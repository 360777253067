.coming-soon {
  background: #0056cd;
  padding: 0 24px;
  @media only screen and (max-width: 1000px) {
    padding: 0;
  }
}

.coming-soon-wrapper {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  .coming-soon-1 {
    flex: 3;
    .coming-soon-ready {
      font-family: Poppins, BR Firma;
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      color: #ffffff;
      margin-bottom: 26px !important;
    }

    .coming-soon-start {
      font-family: Poppins, BR Firma;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      color: #ebf3ff;
      max-width: 550px;
    }
    .store-icons {
      margin-top: 20px;
      img {
        max-width: 90%;
        height: auto;
      }
    }
  }
  .coming-soon-2 {
    flex: 2;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    img {
      width: 100%;
      max-width: 600px;
      object-fit: cover;
      height: auto;
      padding-top: 25px;
    }
  }
}
@media only screen and (max-width: 786px) {
  .coming-soon {
    background: #0056cd;

    .coming-soon-wrapper {
      padding: 30px 0 0 0 !important;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      .coming-soon-1 {
        flex: unset;
        margin-bottom: 20px;
        .coming-soon-ready {
          font-family: Poppins, BR Firma;
          font-style: normal;
          font-weight: 600;
          font-size: 20px !important;
          text-align: center;
          color: #ffffff;
          margin-bottom: 26px !important;
        }

        .coming-soon-start {
          font-family: Poppins, BR Firma;
          font-style: normal;
          font-weight: 300;
          font-size: 14px !important;
          text-align: center;
          color: #ffffff;
        }
        .store-icons {
          display: flex;
          justify-content: center;
        }
      }
      .coming-soon-2 {
        flex: unset;
        display: flex;
        justify-content: center !important;
        align-items: center;
        img {
          height: unset !important;
          width: calc(100% - 88px);
        }
      }
    }
  }
}
