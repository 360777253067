@import "../../index.scss";

.ready-to-get-started {
  padding: 30px 24px;
  @include set-max-width-desktop;
  margin-bottom: 60px;
  @media only screen and (max-width: 786px) {
    margin-bottom: unset !important;
  }
}
.ready-to-get-started > div {
  padding: 70px;
  display: flex;
  justify-content: space-between;
  background: #ebf3ff;
  &.blue {
    background: #0056cd !important;
  }
  .ready-to-get-started-1 {
    flex: 3;
    .ready-to-get-started-ready {
      font-family: Poppins, BR Firma;
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      color: #1e2329;
      margin-bottom: 26px !important;
      &.blue {
        color: #ffffff !important;
      }
    }

    .ready-to-get-started-start {
      font-family: Poppins, BR Firma;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      color: #1e2329;
      &.blue {
        color: #ebf3ff !important;
      }
    }
  }
  .ready-to-get-started-2 {
    flex: 2;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .getstarted-white {
      background: #0056cd;
      border-radius: 5px;
      text-decoration: none;
      font-family: Poppins, BR Firma;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      text-align: center;
      color: #ffffff;
      padding: 18px 30px;
      border: none;
      outline: none;
      &.blue {
        color: #1e2329;
        background: #ffffff;
      }
    }
  }
}
@media only screen and (max-width: 786px) {
  .ready-to-get-started {
    background: #ebf3ff;
    padding: 0px 24px !important;
    &.blue {
      background: #0056cd !important;
    }

    div {
      padding: 30px 0px !important;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      .ready-to-get-started-1 {
        flex: unset;
        margin-bottom: 20px;
        .ready-to-get-started-ready {
          font-family: Poppins, BR Firma;
          font-style: normal;
          font-weight: 600;
          font-size: 20px !important;

          text-align: center;
          color: #1e2329;
          margin-bottom: 26px !important;
        }

        .ready-to-get-started-start {
          font-family: Poppins, BR Firma;
          font-style: normal;
          font-weight: 300;
          font-size: 14px !important;
          text-align: center;
          color: #1e2329;
        }
      }
      .ready-to-get-started-2 {
        flex: unset;
        display: flex;
        justify-content: center !important;
        align-items: center;
      }
    }
  }
}
