.for-you {
  background: linear-gradient(
    180deg,
    rgba(210, 225, 247, 0) 2.56%,
    rgba(210, 225, 247, 0.442308) 67.15%,
    #d2e1f7 100%
  );

  .for-you-wrapper {
    padding-bottom: 32px;

    .for-you-title {
      text-align: center;
      margin: 75px 0;
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;
      color: #1e2329;
      padding: 0 20px;

      @media only screen and (max-width: 786px) {
        margin: 40px 0;
        font-size: 24px;
        line-height: 35px;
      }

      @media only screen and (max-width: 500px) {
        font-size: 19px;
        line-height: 27px;
      }

      span {
        color: #0056cd;
      }
    }

    .for-you-main {
      display: flex;
      flex-direction: row;
      overflow-x: scroll;
      margin-bottom: 43px;
      padding-bottom: 5px;
      scroll-behavior: smooth;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      @media only screen and (max-width: 786px) {
        padding-bottom: 10px;
        margin-bottom: 20px;
      }

      .q-item {
        width: calc(33% - 46px);
        min-width: 400px;
        padding: 23px;
        background: #d6e7ff;
        border-radius: 8px;
        margin-right: 23px;

        @media only screen and (max-width: 786px) {
          padding: 15px;
          min-width: 300px;
          width: calc(100% - 100px);
          margin-right: 20px;
        }
        &:first-of-type {
          margin-left: 23px;
        }

        .q-i-question {
          font-weight: 600;
          font-size: 18px;
          line-height: 140%;
          letter-spacing: -0.005em;
          color: #1e2329;
          margin-bottom: 24px;
          @media only screen and (max-width: 786px) {
            font-size: 16px;
            margin-bottom: 15px;
          }
        }

        .q-i-answer {
          font-size: 14px;
          display: flex;
          @media only screen and (max-width: 786px) {
            font-size: 12px;
          }
          .a-polygon {
            width: 20px;
            margin-right: 5px;
            transform: translateY(3px);
          }

          div {
            width: calc(100% - 20px);
          }
        }
      }
    }
  }
}
