@import "../../index.scss";

.pricing-page-container {
  background: #f5f9ff;
  border-radius: 8px;
  max-width: 1033px;
  margin: 62px auto 0;
  position: relative;
  z-index: 3;

  @media only screen and (max-width: 1043px) {
    margin-top: 0;
    border-radius: 0;
  }
  .o-vect {
    position: absolute;

    z-index: 2;

    &.top {
      right: 0;
      top: 0;
    }

    &.bottom {
      left: 0;
      bottom: 0;
    }
  }

  .pricing-hero {
    padding: 48px 24px;
    @include set-max-width-desktop;
    margin: 0 auto;
    position: relative;
    z-index: 3;
    &.checkout {
      max-width: 950px;
    }
    @media only screen and (max-width: 786px) {
      padding: 30px 0 60px 0;
      width: 90%;
      margin: 0 auto;
      .pricing-hero-wrapper {
        font-size: 24px !important;
      }
    }
    .pricing-hero-wrapper {
      max-width: 550px;
      .pricing-hero-title {
        font-weight: bold;
        font-size: 42px;
        line-height: 120%;
        color: #0056cd;
        margin-bottom: 20px;
      }

      .pricing-hero-desc {
        font-weight: 300;
        font-size: 18px;
        line-height: 30px;
        color: #1e2329;
      }

      .pricing-hero-t1 {
        font-weight: 600;
        font-size: 28px;
        margin-bottom: 24px;
      }

      .pricing-hero-t2 {
        font-weight: 500;
        font-size: 18px;
        color: #1e2329;
        margin-bottom: 10px;
      }

      .pricing-hero-t3 {
        font-size: 14px;
        color: #4f5d6d;
        max-width: 500px;
      }
    }

    .pricing-main {
      margin-top: 70px;
      position: relative;

      .pricing-recommended {
        top: 0;
        left: 50%;
        transform: translate(-50%, -80px);
        position: absolute;
        background: #e6f5e6;
        padding: 8px 12px;
        display: flex;
        align-items: center;
        width: fit-content;
        border-radius: 5px;
        font-size: 13px;
        font-weight: 500;
        color: #1e2329;
        @media only screen and (max-width: 786px) {
          transform: translate(-50%, -65px) !important;
        }
        span {
          margin-left: 7px;
        }
        .arrow-container {
          position: relative;
          @media only screen and (max-width: 786px) {
            display: none;
          }
          .arrow {
            position: absolute;
            transform: translateX(13px);
          }
        }
      }

      .pricing-switch {
        display: flex;
        margin: 0 auto;
        width: fit-content;
        margin-bottom: 24px;
        font-size: 14px;
        background: #f6f7f9;
        border-radius: 100px;
        color: #1e2329;
        font-weight: 500;
        @media only screen and (max-width: 786px) {
          font-size: 12px !important;
          .monthlyp,
          .yearlyp {
            padding: 10px 25px !important;
          }
        }
        .monthlyp {
          padding: 13px 37px;
          border-radius: 100px;
          cursor: pointer;
          &:active {
            opacity: 0.8;
          }
        }
        .yearlyp {
          padding: 13px 37px;
          border-radius: 100px;
          cursor: pointer;
          &:active {
            opacity: 0.8;
          }
        }
        .selected {
          background: #d6e7ff !important;
          color: #0056cd !important;
        }
      }

      .pricing-plans {
        width: 100%;
        max-width: 950px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;

        @media only screen and (max-width: 786px) {
          flex-direction: column;
        }
      }

      .pricing-info {
        width: calc(48% - 35px);
        height: fit-content;
        padding: 40px 20px;
        background: #004db9;
        box-shadow: 0px 14px 30px rgba(0, 69, 164, 0.3);
        border-radius: 8px;
        @media only screen and (max-width: 786px) {
          padding: 30px 15px;
          width: calc(100% - 40px);
          max-width: 400px;
        }

        &.basic {
          background: #ffffff;
          box-shadow: 0px 3px 10px -1px rgba(0, 0, 0, 0.15);
          @media only screen and (max-width: 786px) {
            margin-top: 30px;
          }
          .topp {
            color: #001f4b !important;
          }

          .middlep {
            color: #1e2329 !important;
          }

          .b-text {
            color: black !important;
          }

          .premium-access {
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            display: flex;
            flex-direction: row;
            align-items: center;
            background: #c2dcff;
            border-radius: 4px;
            width: fit-content;
            padding: 2px 4px;
            margin-bottom: 10px;
            margin-top: -10px;
            span {
              margin-left: 8px;
            }
          }
        }

        .topp {
          color: #ebf3ff;
          margin-bottom: 10px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .t-left {
            font-weight: 500;
            font-size: 18px;
          }

          .t-right {
            font-weight: 600;
            font-size: 28px;
          }
        }

        .middlep {
          margin-top: 20px;
          padding-top: 20px;
          border-top: 1px solid #edeff2;
          color: #d6e7ff;
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 10px;
          .middlep-item {
            display: flex;
            margin-bottom: 20px;
            .check-image {
              margin-right: 15px;
            }
          }

          .expand-group {
            @media only screen and (max-width: 786px) {
              &.e-g-hide {
                display: none;
              }
            }
          }

          .expand-card {
            text-align: center;
            font-weight: 500;
            color: white;

            &.text-black {
              color: black;
            }

            @media only screen and (min-width: 786px) {
              display: none;
            }
            .arrow-d {
              margin-left: 5px;
              &.color-white {
                path {
                  stroke: white !important;
                }
              }
            }
          }
        }

        .m-p-divider {
          border-top: 1px solid #edeff2;
          margin-bottom: 20px;
        }

        .pricing-button {
          width: 100%;
          color: #0056cd;
          text-align: center;
          background: #ffffff;
          font-weight: 600;
          padding: 12px 0;
          border-radius: 4px;
          cursor: pointer;

          &.basic {
            color: #1e2329;
            background: #ebeff4;
          }
          &:active {
            opacity: 0.9;
          }
        }
        a {
          color: unset;
          text-decoration: unset;
        }

        .b-text {
          text-align: center;
          font-size: 12px;
          margin-top: 10px;
          color: white;
        }
      }
    }
  }
}

.footer-wrapper-p {
  border-top: 1px solid #edeff2;
}
