@import "../../index.scss";
[id] {
  scroll-margin-top: 6rem;
}

.invisible {
  visibility: hidden;
}
.visible {
  visibility: visible;
}
.landingpage {
  overflow-x: hidden;
  .familybackground {
    .banner-wrapper {
      @include set-max-width-desktop;
      padding: 0 25px;
      min-height: 650px;
      max-height: 800px;
      height: calc(100vh - 90px);
      padding-bottom: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 3rem;

      .showmesomethings {
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 10px;
        .app-links {
          font-weight: 600;
          font-size: 14px;
          color: #0056cd;
          div {
            display: flex;
            align-items: center;
            gap: 12px;
            margin-top: 10px;
            img {
              max-width: 100%;
              height: auto;
            }
          }
        }
      }
      .sided1 {
        position: relative;
        .family-image {
          position: relative;
          .vect121 {
            position: absolute;
            z-index: 3;
            &.ri2 {
              bottom: -75px;
              left: 40px;
            }
            &.wej {
              left: -75px;
              bottom: 80px;
            }
            &.dsg {
              top: 85px;
              left: -75px;
            }
            &.netwo {
              padding: 10px 35px;
              background-color: white;
              box-shadow: 0px 9px 20px 6px rgba(157, 170, 184, 0.2);
              border-radius: 4px;
              text-align: center;
              bottom: 150px;
              right: -15px;
              ::-moz-selection {
                background: transparent;
              }
              ::selection {
                background: transparent;
              }
              .netwo-t {
                font-size: 12px;
                color: #4f5d6d;
                margin-bottom: 5px;
              }
              .netwo-p {
                color: #0056cd;
                font-weight: 600;
                font-size: 18px;
              }
            }
          }
          img {
            position: relative;
            max-width: 100%;
            max-height: 480px;
            height: auto;
            width: auto;
            margin-bottom: -4px;
            z-index: 2;
            background: linear-gradient(
              180deg,
              rgba(255, 255, 255, 0) 48.07%,
              rgba(255, 255, 255, 0.15) 74.55%,
              #ffffff 100%
            );
          }
        }
        .curve {
          top: 0;
          left: 50%;
          transform: translate(-50%, -30px);
          position: absolute;
          z-index: 1;
        }
      }
      .organiset {
        font-family: Poppins, BR Firma;
        font-style: normal;
        font-weight: 800;
        font-size: 46px;
        line-height: 58px;
        color: #1e2329;
        text-align: left !important;
        span {
          color: #0056cd;
        }
      }
      .organisec {
        max-width: 520px;
        font-family: Poppins, BR Firma;
        font-style: normal;
        font-weight: 300;
        font-size: 17px;
        line-height: 30px;
        text-align: left;
        color: #1e2329;
        margin-top: 14px !important;
        margin-bottom: 14px !important;
      }

      .no-card {
        color: #4f5d6d;
        font-size: 12px;
        margin-top: 10px;
        @media only screen and (max-width: 786px) {
          display: none;
        }
      }

      .tryc {
        font-family: Poppins, BR Firma;
        font-style: normal;
        font-weight: normal;
        font-size: 1em;
        line-height: 20px;
        color: #1e2329;
        margin-top: 15px !important;
        span {
          font-weight: bold;
          color: #1e2329;
        }
      }
    }
  }

  .section-2 {
    padding-bottom: 120px;
    @media only screen and (max-width: 786px) {
      padding-bottom: 30px;
    }

    .section-2-wrapper {
      @include set-max-width-desktop;
      padding: 0 25px;
      @media only screen and (max-width: 786px) {
        margin-top: 5rem;
        padding: unset;
      }
      .section-2-inner-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @media only screen and (max-width: 786px) {
          display: block;
          .card22 {
            width: calc(100% - 50px) !important;

            max-width: 450px;
            padding: 10px 25px 10px 25px !important;
            .card22-wrapper {
              padding: 1rem 1.4rem !important;
              height: unset !important;

              .desc22 {
                font-size: 13px !important;
              }
            }
          }
        }

        .card22 {
          width: 33%;
          padding: 0.7rem;
          position: relative;

          &:first-of-type {
            padding-left: unset;
          }
          &:last-of-type {
            padding-right: unset;
          }
          .card22-wrapper {
            background: #0056cd;
            box-shadow: 0px 3px 10px -1px rgba(0, 0, 0, 0.15);
            border-radius: 5px;
            padding: 1.7rem 1.4rem 0 1.4rem;
            height: 100%;
            color: #ffffff;

            .title22 {
              font-weight: 600;
              font-size: 18px;
              line-height: 140%;
              margin: 1rem 0;
            }
            .desc22 {
              font-weight: 400;
              font-size: 14px;
              line-height: 140%;
              max-width: 95%;
              color: #ffffff;
            }
          }

          .card-vect {
            position: absolute;
            top: -20px;
            right: -30px;

            &.two {
              transform: rotate(-78.38deg) scaleX(-1);
              top: -40px;
              right: -60px;
            }

            &.three {
              transform: rotate(-102.38deg);
              top: -65px;
              right: -85px;
            }
          }
        }
      }
    }
  }

  .how-it-works {
    @include set-max-width-desktop;
    padding: 50px 25px;

    .cf3 {
      img {
        height: auto;
        max-height: 500px;
      }
    }

    .image-how-it {
      margin: 2rem 0;
      @media only screen and (max-width: 1024px) {
        margin: 4rem 0rem;
      }
      display: flex;
      justify-content: space-between;
      .image-how-it-img-div {
        @media only screen and (min-width: 1024px) {
          width: 50%;
          display: flex;
          justify-content: center;
        }
        img {
          max-width: 90%;
        }
        svg {
          width: 525px;
        }
      }
      @media only screen and (min-width: 786px) {
        .left-img6 {
          padding-right: 20px;
        }
      }

      .image-how-it-info {
        @media only screen and (min-width: 1024px) {
          max-width: 50%;
        }
        @media only screen and (max-width: 786px) {
          width: 100%;
        }

        display: flex;
        flex-direction: column;
        justify-content: center;
        .image-how-it-info-1 {
          font-family: Poppins, BR Firma;
          font-style: normal;
          font-weight: 600;
          font-size: 22px;
          color: #1e2329;
          margin-bottom: 1rem !important;
        }
      }
    }
    .how-it-works-image {
      &.hi3 {
        max-width: 468px !important;
        height: auto;
      }

      @media only screen and (max-width: 786px) {
        max-width: calc(100vw - 50px);
        height: unset !important;
      }
    }
  }
}

.getstarted {
  background: #0056cd;
  border-radius: 5px;
  width: fit-content;
  text-decoration: none;
  font-family: Poppins, BR Firma;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  padding: 15px 27px;
  border: none;
  outline: none;
  margin-right: 30px;
}

.learn-more {
  @extend .getstarted;
  width: auto;
  height: auto;
  padding: 15px;
  text-decoration: none;

  @media only screen and (max-width: 786px) {
    margin: 0 !important;
    max-width: 80%;
  }
}

@media only screen and (max-width: 786px) {
  .familybackground {
    .banner-wrapper {
      padding: 30px 10px 0px 10px !important;
      display: flex;
      height: unset !important;
      max-height: unset !important;
      flex-direction: column !important;
      margin-bottom: 5rem !important;
      .showmesomethings {
        width: unset !important;
        display: flex;
        flex-direction: column;
        padding: 0px 15px;
        a {
          display: none !important;
        }
      }
      .sided1 {
        display: flex;
        position: relative;
        width: 100% !important;
        margin-top: 10px;
        padding: 0 15px;
        .family-image {
          .vect121 {
            height: 100px;
            width: 100px;
          }
          .wej {
            left: unset !important;
            right: 30px !important;
            top: -20px !important;
          }
          .dsg {
            top: -20px !important;
            left: unset !important;
          }
          .ri2 {
            bottom: -47px !important;
          }
          .netwo {
            height: unset !important;
            width: unset !important;
            padding: 7px 25px;
            right: -5px !important;
            bottom: 100px !important;
          }
          img {
            margin-top: 30px;
            width: 100%;
            max-width: calc(100vw - 50px);
            height: 100%;
            margin-bottom: unset !important;
            transform: unset !important;
          }
        }
      }

      .organisec {
        font-family: Poppins, BR Firma;
        font-style: normal;
        font-weight: 300;
        font-size: 1em !important;
        line-height: 25px;
        color: #ffffff;
        margin-top: 14px !important;
        margin-bottom: 0px !important;
      }

      .tryc {
        display: none !important;
      }
    }
  }

  .how-it-works {
    padding: 85px 70px 100px;
  }

  .image-how-it {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    .image-how-it-img-div {
      svg {
        width: 200px;
      }
      margin-bottom: 15px;
    }
    .left-img6 {
      padding-right: 20px;
    }

    .image-how-it-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .image-how-it-info-1 {
        font-family: Poppins, BR Firma;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        color: #1e2329;
      }
      .image-how-it-info-2 {
        font-family: Poppins, BR Firma;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        color: #4f5d6d;
      }
    }
  }

  .depictreverse {
    flex-direction: column-reverse !important;
  }

  @media only screen and (min-width: 745px) and (max-width: 786px) {
    .pixalo {
      img {
        max-height: 300px;
      }
    }
  }

  .pixalo {
    img {
      width: 450px;
    }
  }
}

@media only screen and (max-width: 745px) {
  .how-it-works {
    padding: 5px 25px !important;
  }
  .pixalo img {
    max-width: 96%;
    max-height: 200px;
  }

  .image-how-it {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    .image-how-it-img-div {
      img {
        width: 90%;
      }
      svg {
        width: 90%;
      }
    }
    .left-img6 {
      padding-right: unset;
    }

    .image-how-it-info {
      .image-how-it-info-1 {
        font-size: 20px !important;
        margin-bottom: 1rem !important;
      }
      .image-how-it-info-2 {
        font-size: 16px !important;
      }
    }
  }
}

.section-text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 45%;
  @media only screen and (max-width: 786px) {
    width: 100%;
    .title111 {
      font-size: 22px !important;
    }
  }
  .title111 {
    font-weight: 700;
    font-size: 24px;
    color: #0056cd;
    margin-bottom: 40px;
  }
  .top111 {
    margin-bottom: 60px;
  }
  .top111,
  .bottom111 {
    div:nth-of-type(1) {
      font-weight: 500;
      margin-bottom: 11px;
      color: #1e2329;
      display: flex;
      align-items: center;
      svg {
        margin-right: 10px;
      }
    }
    div:nth-of-type(2) {
      color: #4f5d6d;
      max-width: 550px;
      line-height: 28px;
    }
  }
}

.scroll-btns {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .sb-left {
    margin-right: 24px;
    transform: rotate(180deg);
  }
}
