@import "../../index.scss";

.authpage {
  &.no-border {
    border-top: unset;
  }
  .authheader {
    margin: auto;
    border-top: 7px solid #0056cd;
    padding: 20px 50px 35px 50px;
    top: 0;
    position: -webkit-sticky;
    position: sticky;
    z-index: 4;
    background: white;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.08);

    .ttt {
      display: flex;
      align-items: flex-end;
    }
  }
  .divideauthpage {
    margin: auto;
    display: flex;
    align-items: center;
    font-family: Poppins;
    align-items: flex-start;

    .authheader2 {
      margin-bottom: 4rem;
      width: 100%;
      .ttt {
        display: flex;
        align-items: flex-end;
      }
    }

    .firsthalf {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      min-height: calc(100vh - 23px);
      height: 100%;
      width: 50%;
      background-image: url("../../assets/svg/sign-up-bg.svg");
      background-position: top left;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 1rem 1rem 0 1rem;
      margin-top: 0vh;

      .bottom {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        max-height: 850px;
      }

      .block {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        height: 100%;
        width: fit-content;

        .heading {
          color: #001f4b;
          font-weight: 600;
          font-size: 42px;
          line-height: 63px;
          max-width: 490px;
          width: 100%;
          padding: 0rem 0rem 1rem 0rem;
        }

        .blue-text {
          color: #0056cd;
        }

        .text {
          padding: 0.5rem 0rem;
          font-size: 16px;
          line-height: 28px;
          color: #001f4b;
          max-width: 442px;
          width: 100%;
          margin-bottom: 64px !important;
        }

        .secure-box {
          margin: 1.5rem 0rem;
          background: #fff;
          max-width: 465px;
          border: 1px solid #0056cd;
          border-radius: 6.63566px;
          padding: 14px 10px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .secure-text {
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            width: 100%;
            padding-left: 20px;
            max-width: 320px;
          }

          img {
            width: 82.95px;
            height: 82.95px;
            margin-right: 0.5rem;
          }
        }
      }

      .rate {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        max-width: 455px;
        width: 100%;
        margin-bottom: 68px;

        .rate-text {
          font-weight: 400;
          font-size: 14px;
          max-width: 383px;
          line-height: 21px;
          color: #001f4b;
          margin-bottom: 10px !important;
        }

        .user-section {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .user {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin-right: 18px;
          }

          .star-section {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 12px;
          }
        }
      }
    }
    .secondhalf {
      flex: 1;
      display: flex;
      justify-content: center;
      margin-top: 0vh;
      padding: 0rem 1rem 0rem 1rem;
      min-height: calc(100vh - 23px);
      height: 100%;

      .holder {
        max-width: 440px;
        width: 100%;
      }
    }

    @media screen and (max-width: 768px) {
      .secondhalf {
        min-height: auto;
      }
    }

    .welcome {
      display: flex;
      justify-content: space-between;
      margin-bottom: 35px;
      .h3-size {
        color: #1e2329;
      }

      .signin {
        color: #0056cd;
      }
    }
    .googlebuttondiv {
      margin-bottom: 23px;
      button {
        width: 100%;
        display: flex;
        background: #f6f7f9;
        border: 1px solid #edeff2;
        box-sizing: border-box;
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        height: 45px;
        span {
          margin-left: 6px;
          color: #1e2329;
          margin-top: 2px;
        }
      }

      botton:focus {
        outline: none;
      }
    }
    .googleicon {
      width: 20px;
    }
    .ordiv {
      margin-bottom: 22px;
      border-bottom: 1px solid #edeff2;
      .p-normal {
        color: #abafaf;
        text-align: center;
        margin-bottom: 15px !important;
      }
    }
    .authbutton {
      margin-bottom: 20px;
    }
  }
  .forgotpassword {
    padding: 0px 15px;
    display: flex;
    justify-content: center;
    margin-top: 10vh;
    .inner {
      max-width: 434px;
      width: 100%;
    }
    .h3-size {
      color: #1e2329;
      margin-bottom: 51px !important;
    }
    .emailsent {
      margin-bottom: 6px !important;
    }
    .btn-md {
      width: 245px;
    }
    .verifyemail {
      width: 289px;
    }
    .placecenter {
      margin-bottom: 75px;
    }
    .authbutton2 {
      margin-bottom: 30px;
    }
    .message {
      color: #1e2329;
      margin-top: 57px !important;
      margin-bottom: 15px !important;
    }
  }

  .passworderrors {
    margin-top: 5px;
    p {
      display: flex;
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height */

      display: flex;
      align-items: flex-end;

      /* Type:/Primary Type */

      color: #1e2329;
      margin-bottom: 5px !important;
    }
    .icons {
      margin-right: 5px;
    }
  }
}
.signup {
  .form-control {
    margin-bottom: 16px;
  }

  .secondhalf {
    margin-top: 3vh;
  }
  .welcome {
    margin-bottom: 25px;
  }
}
.pincode-input-container {
  display: flex;
  justify-content: space-between;
  // margin-bottom: 70px;
  .pincode-input-text {
    padding: 0 !important;
    margin: 0 2px !important;
    text-align: center !important;
    width: 50px !important;
    height: 50px !important;
    background: #f6f7f9 !important;
    border: 1px solid #edeff2 !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
  }
  .pincode-input-text:focus {
    outline: none;
    box-shadow: none;
  }
}

.secure-box-small-screen {
  display: none;
}

.block-small-screen {
  display: none;
}

@media only screen and (max-width: 768px) {
  .authpage {
    .authheader {
      margin: auto;
      padding: 15px 15px 20px 15px;
      top: 0;
      position: -webkit-sticky;
      position: sticky;
      z-index: 4;
      background: white;
      display: block;

      .ttt {
        display: flex;
        align-items: flex-end;
      }
      .logocontainer {
        img {
          height: 20px;
          width: 50px;
        }
      }
    }
  }
  .divideauthpage {
    .firsthalf {
      display: none !important;
      flex: 1 !important;
    }
    .secondhalf {
      flex: 1;
      .signin {
        display: none;
      }
      .welcome {
        margin-bottom: 27px;
      }
    }
  }

  .secure-box-small-screen {
    margin: 5rem auto 0rem auto;
    max-width: 448px;
    background: #d6e7ff;
    padding: 14px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .secure-text-small-screen {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      width: 100%;
      max-width: 396.78px;
      color: #0056cd;
    }

    img {
      width: 37.87px;
      height: 30.66px;
      margin-right: 0.5rem;
    }
  }

  .block-small-screen {
    padding: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
    width: fit-content;
    margin: 8px auto 24px auto;

    .heading-small-screen {
      color: #001f4b;
      font-weight: 600;
      font-size: 32px;
      line-height: 44px;
      max-width: 448px;
      width: 100%;
      margin-bottom: 12px !important;
    }

    .blue-text {
      color: #0056cd;
    }

    .text-small-screen {
      font-size: 16px;
      line-height: 24px;
      color: #001f4b;
      max-width: 442px;
      width: 100%;
    }
  }
}

.terms_page {
  max-width: 1440px;
  margin: auto;
}

.terms_list_body_contaner {
  padding: 40px 50px;
  max-width: 1000px;
  margin: auto;
}

ul.terms_list_body_contaner,
ul.terms_body {
  list-style-type: none;
  counter-reset: terms-counters 0;
  li {
    position: relative;
  }
  & > li::before {
    position: absolute;
    left: 1rem;
    line-height: 28px;
    font-size: 22px;
    height: 22px;
    counter-increment: terms-counters;
    content: counters(terms-counters, ".");
    @media only screen and (max-width: 768px) {
      left: -1rem;
    }
  }
}

ul.terms_body {
  li {
    padding-left: 2.5rem;
    &::before {
      left: 0;
      font-size: inherit;
      line-height: inherit;
    }
  }
}
.terms_intro {
  background: #ebf3ff;
}

.terms_intro > div {
  @include set-max-width-desktop;
  height: fit-content;
  padding-top: 4rem;
  padding-bottom: 4rem;
  font-style: normal;
  .terms_container {
    h1,
    p {
      @media only screen and (max-width: 768px) {
        max-width: 100%;
      }
    }
    h1 {
      font-weight: bold;
      font-size: 48px;
      line-height: 60px;
      margin-bottom: 20px !important;
      span {
        &:first-of-type {
          color: #0056cd;
        }
        &:nth-of-type(2) {
          color: #00b0ef;
        }
      }
    }
    p {
      font-weight: 300;
      font-size: 18px;
      line-height: 33px;
      color: #1e2329;
    }
  }
}

.terms_container {
  padding: 0 50px;
  @media only screen and (max-width: 768px) {
    padding: 0 15px;
  }
  @include set-max-width-desktop;
}
.terms_head {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 1rem !important;
  counter-reset: css-counters 0;
  position: relative;
}

.terms_head::before {
  counter-increment: css-counters;
  content: 2 !important;
  position: absolute;
  top: 0;
  left: 0;
}

.terms_subhead {
  font-weight: 500;
  margin-bottom: 0.5rem !important;
}

ul {
  margin-top: 1rem;
  &.disc {
    margin-left: 2rem;
    list-style: disc;
  }
  &.roman {
    margin-left: 2rem;
    list-style: lower-roman;
  }
  & > li {
    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }
}

.terms_body {
  font-weight: 300;
  margin-bottom: 1rem !important;
}

.terms_subhead,
.terms_body {
  font-size: 16px;
  font-style: normal;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
}

div .terms_container {
  &:not(:last-of-type) {
    margin-bottom: 4.375rem;
  }
}

.terms_get_started {
  width: 100%;
  height: fit-content;
  padding-top: 4rem;
  padding-bottom: 4rem;
  color: white;
  background: #0056cd;
  margin-top: 4rem;

  & > div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media only screen and (max-width: 768px) {
      display: block;
    }
    .content {
      width: 60%;
      @media only screen and (max-width: 768px) {
        width: 100%;
      }
      text-align: left;
      font-style: normal;
      h4 {
        font-size: 28px;
        font-weight: 600;
        line-height: 35px;
        letter-spacing: 0em;
      }
      p {
        font-size: 18px;
        font-weight: 300;
        line-height: 23px;
        letter-spacing: 0em;
        margin-top: 26px;
      }
    }
    .button {
      @media only screen and (max-width: 768px) {
        margin-top: 26px;
      }
      a {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 18px 30px;
        width: 220px;
        height: 56px;
        background: #ffffff;
        border-radius: 5px;

        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #001f4b;
      }
    }
  }
}

.beta-flag {
  font-size: 0.875rem /* 14px */;
  line-height: 1.375rem /* 22px */;
  --tw-text-opacity: 1;
  color: rgba(36, 84, 33, var(--tw-text-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(219, 240, 218, var(--tw-bg-opacity));
  padding-left: 0.25rem /* 4px */;
  padding-right: 0.25rem /* 4px */;
  padding-top: 0.125rem /* 2px */;
  padding-bottom: 0.125rem /* 2px */;
  border-radius: 0.25rem /* 4px */;
  margin-left: 0.5rem /* 8px */ !important;
  font-family: Poppins;
  transform: translateY(2px);
  &.blue {
    text-transform: uppercase;
    color: #0056cd;
    background: #d6e7ff;
    font-weight: 500;
    border-radius: 4px;
  }
}

.no-underline {
  text-decoration: none;
}

.signup-container {
  max-width: 28rem /* 448px */;
  width: 100%;
  margin-top: 109px;
}

.top-div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem /* 32px */;
}

@media (max-width: 768px) {
  .top-div {
    display: none;
  }

  .signup-container {
    margin-top: 0px;
  }
}

.top-text {
  font-size: 1.5rem /* 24px */;
  line-height: 2.25rem /* 36px */;
  font-weight: 500;
}

.link-text {
  font-size: 1rem /* 16px */;
  line-height: 1.5rem /* 24px */;
  font-weight: 600;
  margin: auto 0;
  --tw-text-opacity: 1;
  color: rgba(0, 86, 205, var(--tw-text-opacity));
}

.google-btn-div {
  margin-bottom: 1rem /* 16px */;
}

.google-btn {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 2.75rem /* 44px */;
  border-radius: 0.25rem /* 4px */;
  border-width: 1px;
  --tw-bg-opacity: 1;
  background-color: rgba(246, 247, 249, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(237, 239, 242, var(--tw-border-opacity));
  border-style: solid;
  padding: 0px;
  cursor: pointer;
}

.google-text {
  font-size: 1rem /* 16px */;
  line-height: 1.5rem /* 24px */;
  font-weight: 600;
  margin-left: 0.375rem /* 6px */;
  --tw-text-opacity: 1;
  color: rgba(30, 35, 41, var(--tw-text-opacity));
  font-family: Poppins;
}

.or-div {
  border-width: 0px;
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(237, 239, 242, var(--tw-border-opacity));
  border-style: solid;
  margin-bottom: 1rem /* 16px */;
}

.or-text {
  font-size: 0.875rem /* 14px */;
  line-height: 1.375rem /* 22px */;
  --tw-text-opacity: 1;
  color: rgba(171, 175, 175, var(--tw-text-opacity));
  text-align: center;
}

.err-message {
  font-size: 1rem /* 16px */;
  line-height: 1.5rem /* 24px */;
  font-weight: 500;
}

.danger-not {
  color: #5d0814;
  background: #fcd9de;
}

.notification {
  border-radius: 0.3125rem;
  padding: 1.25rem;
}

.grid-lay {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: repeat(1, minmax(0, 1fr));
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 0px;

  @media (min-width: 768px) {
    & {
      -ms-grid-columns: repeat(2, minmax(0, 1fr));
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 1rem /* 16px */;
    }
  }
}

.input-div {
  margin-bottom: 1rem /* 16px */;
}

.input-body {
  display: flex;
  flex-direction: column;
}

.input-label {
  font-size: 0.875rem /* 14px */;
  line-height: 1.375rem /* 22px */;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgba(30, 35, 41, var(--tw-text-opacity));
  margin-bottom: 0.25rem /* 4px */;
}

.input-usual {
  border-style: solid;
  font-family: Poppins;
  outline: none;
  border-width: 1px;
  --tw-bg-opacity: 1;
  background-color: rgba(246, 247, 249, var(--tw-bg-opacity));
  border-radius: 0.25rem /* 4px */;
  font-size: 0.875rem /* 14px */;
  line-height: 1.375rem /* 22px */;
  --tw-text-opacity: 1;
  color: rgba(30, 35, 41, var(--tw-text-opacity));
  font-weight: 400;
  padding-left: 0.5rem /* 8px */;
  padding-right: 0.5rem /* 8px */;
  padding-top: 0.75rem /* 12px */;
  padding-bottom: 0.75rem /* 12px */;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow,
    transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.input-usual:focus {
  --tw-border-opacity: 1;
  border-color: rgba(30, 35, 41, var(--tw-border-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width)
    var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width))
    var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.input-usual::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(171, 175, 175, var(--tw-placeholder-opacity));
  font-family: Poppins;
}

.input-danger {
  --tw-border-opacity: 1;
  border-color: rgba(238, 66, 90, var(--tw-border-opacity));
}

.input-neutral {
  --tw-border-opacity: 1;
  border-color: rgba(237, 239, 242, var(--tw-border-opacity));
}

.input-small {
  font-size: 0.75rem /* 12px */;
  line-height: 1.125rem /* 18px */;
  --tw-text-opacity: 1;
  color: rgba(238, 66, 90, var(--tw-text-opacity));
  margin-top: 0.25rem /* 4px */;
}

.pointer {
  cursor: pointer;
}

.input-check {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input-unusual {
  border-style: solid;
  font-family: Poppins;
  outline: none;
  border-width: 1px;
  --tw-bg-opacity: 1;
  background-color: rgba(246, 247, 249, var(--tw-bg-opacity));
  border-radius: 0.25rem /* 4px */;
  font-size: 0.875rem /* 14px */;
  line-height: 1.375rem /* 22px */;
  --tw-text-opacity: 1;
  color: rgba(30, 35, 41, var(--tw-text-opacity));
  font-weight: 400;
  padding-left: 0.5rem /* 8px */;
  padding-right: 0.5rem /* 8px */;
  padding-top: 0.65rem /* 12px */;
  padding-bottom: 0.65rem /* 12px */;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow,
    transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.input-unusual:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(30, 35, 41, var(--tw-border-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width)
    var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width))
    var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.input-eye {
  width: 100%;
  border-style: solid;
  outline: none;
  border-width: 0px;
  --tw-bg-opacity: 1;
  background-color: rgba(246, 247, 249, var(--tw-bg-opacity));
  border-radius: 0.25rem /* 4px */;
  font-size: 0.875rem /* 14px */;
  line-height: 1.375rem /* 22px */;
  --tw-text-opacity: 1;
  color: rgba(30, 35, 41, var(--tw-text-opacity));
  font-weight: 400;
}

.input-eye::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(171, 175, 175, var(--tw-placeholder-opacity));
  font-family: Poppins;
}

.input-eye:focus {
  --tw-border-opacity: 1;
  border-color: rgba(30, 35, 41, var(--tw-border-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width)
    var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width))
    var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.err-deck-1 {
  display: flex;
  --tw-text-opacity: 1;
  color: rgba(30, 35, 41, var(--tw-text-opacity));
  margin-bottom: 0.25rem /* 4px */;
  font-weight: 500;
  align-items: center;
  font-size: 0.75rem /* 12px */;
  line-height: 1.125rem /* 18px */;
  justify-content: flex-start;
}

.mr-1 {
  margin-right: 0.25rem /* 4px */;
}

.text-p1 {
  font-size: 1rem /* 16px */;
  line-height: 1.5rem /* 24px */;
}

.move-right {
  width: 100%;
  margin-right: 1rem;
}

.space-down {
  margin-bottom: 0.5rem;
}

.term-div {
  width: 91.666667%;
  margin-left: auto;
  margin-right: auto;
}

.term-div2 {
  width: 91.666667%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px !important;
}

.term-text {
  text-align: center;
  font-size: 0.875rem /* 14px */;
  line-height: 1.375rem /* 22px */;
  --tw-text-opacity: 1;
  color: rgba(30, 35, 41, var(--tw-text-opacity));
}

.term-inner {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgba(0, 86, 205, var(--tw-text-opacity));
  font-weight: 600;
  text-decoration: none;
}

.btn-most {
  font-family: Poppins;
  font-weight: 600;
  border-radius: 0.25rem /* 4px */;
  border-style: solid;
  border-width: 0px;
  cursor: pointer;
  font-size: 0.875rem /* 14px */;
  line-height: 1.375rem /* 22px */;
  text-align: center;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow,
    transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.btn-big {
  padding-left: 1.5rem /* 24px */;
  padding-right: 1.5rem /* 24px */;
  padding-top: 0.75rem /* 12px */;
  padding-bottom: 0.75rem /* 12px */;
}
.btn-small {
  padding-left: 1rem /* 16px */;
  padding-right: 1rem /* 16px */;
  padding-top: 0.5rem /* 8px */;
  padding-bottom: 0.5rem /* 8px */;
}

.btn-clickable {
  opacity: 1;
}

.btn-not-allowed {
  opacity: 0.4;
  cursor: not-allowed;
}

.btn-prim {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 86, 205, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.btn-prim:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 69, 164, var(--tw-bg-opacity));
}

.btn-sec {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 239, 242, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(79, 93, 109, var(--tw-text-opacity));
}

.btn-sec:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(225, 231, 236, var(--tw-bg-opacity));
}

.btn-del {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 66, 90, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.btn-del:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(228, 33, 60, var(--tw-bg-opacity));
}

.btn-other {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 86, 205, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.btn-other:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 69, 164, var(--tw-bg-opacity));
}

.success-page-container {
  border-top: 7px solid #0056cd;
  overflow-x: hidden;
  @media only screen and (max-width: 768px) {
    padding-bottom: 100px;
  }
  .app-logo {
    display: flex;
    margin-top: 15px;
    margin-left: 20px;
    margin-bottom: 40px;
    .beta-flag {
      width: fit-content;
    }
  }
}

.subscription-container {
  top: 0;
  left: 0;
  border-radius: 0px !important;
  right: 0;
  bottom: 0;
  width: 100vw !important;
  height: fit-content !important;
  max-width: none !important;
  max-height: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-container {
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0 20px;
  width: calc(100% - 40px);
  max-width: 641px;
  font-family: Poppins;
  padding: 48px 50px;
  background: #f5f9ff;
  border-radius: 4px;
  position: relative;
  margin-bottom: 20px;
  @media only screen and (max-width: 548px) {
    padding: 20px;
    margin: 0;
    width: 100%;
    border-radius: unset;
  }
}

.o-vect {
  position: absolute;

  z-index: 2;

  &.top {
    right: 0;
    top: 0;
  }

  &.bottom {
    left: 0;
    bottom: 0;
  }
}

.f-c-inner {
  background: #ffffff;
  box-shadow: 0px 9px 20px 6px rgba(157, 170, 184, 0.2);
  border-radius: 8px;
  width: 100%;
  max-width: calc(100% - 128px);
  padding: 48px 64px;
  position: relative;
  z-index: 3;

  @media only screen and (max-width: 568px) {
    padding: 24px;
    max-width: calc(100% - 48px);
  }
}

.thankyou-header {
  margin-bottom: 30px;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  color: #1e2329;
  @media only screen and (max-width: 800px) {
    font-size: 1rem /* 12px */;
  }
}

.thankyou-sub {
  color: #1e2329;
  margin-bottom: 8px;
  text-align: left;
  font-size: 0.875rem /* 14px */;
  line-height: 1.375rem /* 22px */;
  @media only screen and (max-width: 800px) {
    font-size: 0.75rem /* 12px */;
    line-height: 1.125rem /* 18px */;
  }
}

.btn-wrapper-z {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.deck-other {
  width: 100%;
  margin-top: 6px;
  margin-bottom: 6px;
}

.deck-selected {
  background: #dbeaff;
  border-radius: 4px;
}

.deck-unselected {
  background: #f6f7f9;
  border-radius: 4px;
}

.deck-normal {
  border-width: 1px;
  border-radius: 0.25rem /* 4px */;
  margin-top: 6px;
  margin-bottom: 6px;
  font-size: 0.875rem /* 14px */;
  line-height: 1.375rem /* 22px */;
  --tw-text-opacity: 1;
  color: rgba(30, 35, 41, var(--tw-text-opacity));
  padding-left: 1rem /* 16px */;
  padding-right: 1rem /* 16px */;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  align-items: center;
  cursor: pointer;
  width: calc(100% - 33px);
}

.deck-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.875rem /* 14px */;
  @media only screen and (max-width: 800px) {
    font-size: 0.75rem /* 12px */;
    line-height: 1.125rem /* 18px */;
  }
}

.select-circle {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  margin-right: 8px;
}

.circle-selected {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 86, 205, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(0, 86, 205, var(--tw-border-opacity));
}

.circle-unselected {
  background: #f6f7f9;
  border: 1px solid #a1acaf;
}

.other-title {
  font-weight: 500;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 4px;
}

.other-input {
  width: calc(100% - 34px);
  min-width: calc(100% - 34px);
  max-width: calc(100% - 34px);
  min-height: 30px;
  max-height: 100px;
  padding: 12px 16px;
  border: 1px solid #e1e7ec;
  border-radius: 4px;
  font-family: inherit;
  &::placeholder {
    color: #a1acaf;
  }
}

.thankyou-button {
  margin: 1rem 0rem;
  align-self: flex-end;
}

.text-center {
  text-align: center;
}

.width-full {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.primary-link {
  color: #0056cd;
}

.lighgrey {
  color: #1e2329;
}

.text-nowrap {
  white-space: nowrap;
}

.danger {
  color: #ee425a;
}

.errormessage {
  margin-top: 0.625rem;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/** Used to define container behavior: width, position: fixed etc... **/

/** Classes for the displayed toast **/
.Toastify__toast {
  box-shadow: 0px 3px 10px -1px rgba(0, 0, 0, 0.15) !important;
  background: #fff !important;
}

.Toastify__toast--default {
  background: #fff !important;
  color: #000 !important;
}
.Toastify__toast--info {
  background: #fff !important;
  color: #000 !important;
}
.Toastify__toast--success {
  background: #fff !important;
  color: #000 !important;
}
.Toastify__toast--warning {
  background: #fff !important;
  color: #000 !important;
}
.Toastify__toast--error {
  background: #fff !important;
  color: #000 !important;
}
.Toastify__toast-body {
  background: #fff !important;
  font-family: Poppins !important;
  font-size: 0.875rem;
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
  color: #fff !important;
  // display: none !important;
}
.Toastify__close-button:hover,
.Toastify__close-button:focus {
  color: #fff !important;
}

/** Classes for the progress bar **/
.Toastify__progress-bar {
  background-color: #fff !important;
  color: #fff;
}

.toast-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.toast-div img {
  width: 2rem;
  height: 2rem;
}

.collab-notification {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  background: #ebf3ff;
  border-radius: 5px;
  margin-bottom: 16px;
  font-size: 12px;
  line-height: 20px;

  img {
    margin-right: 9px;
    width: 14px;
    height: 14px;
  }
}

.r-code-input321 {
  width: 100%;
  margin-bottom: 10px;
  input {
    width: calc(100% - 20px);
    background: white;
    &:focus-visible {
      border: 2px solid black;
    }
  }
}

.link-itm {
  color: #0056cd !important;
  text-decoration: underline;
  cursor: pointer;
}
