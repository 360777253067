@import "../../index.scss";

.ads-page-container {
  .banner21 {
    margin-bottom: 80px;

    @media screen and (min-width: 769px) {
      min-height: 750px;
      position: relative;
      background-repeat: no-repeat;
      background-image: url("../../assets/svg/banner-bg-lg.svg");
      background-position: center;
      background-size: cover;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 50px;
    }

    .banner-wrapper {
      @include set-max-width-desktop;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 45px 20px 0;
      @media screen and (max-width: 768px) {
        display: block;
        padding-top: 0px;
      }

      .b-w-left {
        width: 52%;
        padding-top: 40px;

        @media screen and (max-width: 768px) {
          width: 100%;
        }

        .b-w-l-row-one {
          font-size: 24px;
          color: #1e2329;
          margin-bottom: 27px;

          @media screen and (max-width: 900px) {
            font-size: 20px;
          }
          @media screen and (max-width: 768px) {
            font-size: 16px;
            margin-bottom: 16px;
          }
        }

        .b-w-l-row-two {
          font-weight: 700;
          font-size: 46px;
          line-height: 57px;
          letter-spacing: -0.005em;
          color: #1e2329;
          max-width: 520px;

          span {
            color: #0056cd;
          }

          @media screen and (max-width: 1000px) {
            font-size: 40px;
            line-height: 50px;
          }

          @media screen and (max-width: 900px) {
            font-size: 40px;
          }

          @media screen and (max-width: 568px) {
            font-size: 34px;
            line-height: 48px;
          }
        }

        .b-w-l-row-three {
          color: #4f5d6d;
          font-size: 14px;
          line-height: 20px;
          margin-top: 8px;
          margin-bottom: 12px;
          max-width: 550px;
          @media screen and (min-width: 769px) {
            display: none;
          }
        }

        .b-w-l-features {
          @media screen and (max-width: 768px) {
            display: none;
          }
        }
      }

      .b-w-right {
        background: #ffffff;
        box-shadow: 0px 3px 10px -1px rgba(0, 0, 0, 0.15);
        border-radius: 7.21824px;
        width: 42%;
        max-width: 447px;
        padding: 20px;

        @media screen and (max-width: 768px) {
          width: 100%;
          margin: 0 -20px;
          border-radius: 0;
          box-shadow: 0px 3px 10px -1px rgba(0, 0, 0, 0.15);
          max-width: unset;
          padding: 42px 20px;

          ::-webkit-input-placeholder {
            font-size: 12px;
          }
          :-ms-input-placeholder {
            font-size: 12px;
          }
          ::placeholder {
            font-size: 12px;
          }
        }

        .b-w-right-title {
          color: #000000;
          font-weight: 600;
          font-size: 20px;
          margin-bottom: 27px;
          @media screen and (max-width: 768px) {
            display: none;
          }
        }

        .terms-info {
          color: #4f5d6d;
          font-size: 10px;
          line-height: 18px;
          margin-top: 35px;
          text-align: center;

          .click {
            color: #1e2329;
            text-decoration: underline;
          }

          .lg-only {
            @media screen and (max-width: 768px) {
              display: none;
            }
          }
        }
      }

      .f-wrapper {
        @media screen and (min-width: 769px) {
          display: none;
        }
      }
    }
  }

  .b-w-l-features {
    margin-top: 126px;

    @media screen and (max-width: 768px) {
      margin: 48px -20px 0;
      padding: 70px 20px 20px;
      background-repeat: no-repeat;
      background-image: url("../../assets/svg/banner-bg-sm.svg");
      background-size: cover;
    }

    .f-item {
      display: flex;
      margin-bottom: 32px;

      .f-o-text {
        margin-left: 16px;
        color: #ffffff;
        line-height: 19px;
        max-width: 469px;

        @media screen and (max-width: 1000px) {
          font-size: 14px;
        }
      }
    }
  }

  .for-you-wrapper {
    padding-bottom: 72px !important;

    @media screen and (max-width: 768px) {
      padding-bottom: 42px !important;
    }
  }

  .testimonies-section {
    // padding-top: 42px;
    // margin: 47px 0;
    padding: 82px 0;
    margin-bottom: 0px;

    .title911 {
      margin-bottom: 0px !important;
    }
  }

  .covafooter-2 {
    margin-bottom: 0px !important;
  }
}
