.business-details {
  padding: 24px;
  width: calc(100vw - 96px);
  max-width: 600px;
  margin: 0 auto;

  @media only screen and (max-width: 568px) {
    padding: 15px;
    width: calc(100vw - 54px);
  }

  .b-d-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .h-title {
      font-size: 20px;
      font-weight: 600;
    }
  }

  .b-d-form {
    margin-top: 24px;

    .form-item {
      margin-bottom: 10px;
    }

    .b-btn {
      margin-top: 24px;
    }
  }
}

.ReactModalPortal > div {
  background: rgba(0, 0, 0, 0.708) !important;
}
