@import "../../index.scss";

.headercomp {
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.08);
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 300;

  .desktop {
    background: #ffffff;
  }

  .mobile > div {
    .covalogo {
      text-decoration: none;
      display: flex;
      align-items: flex-end;

      .green {
        background: #c7e7c5;
        border-radius: 4px;
        line-height: 1;
        padding: 2px 4px;
        height: 0.8125rem;
        font-size: 0.6875rem;
        font-family: Poppins, BR Firma;
        font-weight: 100;
        margin-left: 10px !important;
      }
    }
  }
  .desktop > div {
    @include set-max-width-desktop-larger;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 25px;
    height: 70px;

    .covalogo {
      text-decoration: none;
      display: flex;
      align-items: flex-end;

      .green {
        background: #c7e7c5;
        border-radius: 4px;
        line-height: 1;
        padding: 2px 4px;
        height: 0.8125rem;
        font-size: 0.6875rem;
        font-family: Poppins, BR Firma;
        font-weight: 100;
        margin-left: 10px !important;
      }
    }
    .alllinks {
      display: flex;
      flex-direction: row;
      align-items: center;

      .textsd {
        font-weight: 600;
        margin-right: 16px;
      }

      .linksd {
        cursor: pointer;
        font-family: Poppins, BR Firma;
        font-style: normal;
        font-weight: 400;
        font-size: 1em;
        line-height: 20px;
        color: #1e2329;
        text-decoration: none;
        margin-right: 3vw;
        user-select: none;
        font-size: 16px;

        &.about {
          position: relative;
          transition: 0.5s;

          .about-content {
            position: absolute;
            top: 35px;
            left: 0;
            width: 140px;
            background: #ffffff;
            box-shadow: 0px 2px 20px 6px rgba(79, 93, 109, 0.2);
            border-radius: 4px;
            padding: 8px;
            display: none;

            &.show-a {
              display: block;
            }

            div {
              color: #1e2329;
              padding: 10px 8px;
              margin-bottom: 6px;
              &:hover {
                background: #ebf3ff;
                border-radius: 4px;
              }
              .linksd {
                margin-right: 0px;
              }
            }
          }
        }

        @media only screen and (min-width: 1600px) {
          margin-right: 60px;
        }
      }

      .linksd:active,
      .signin:active {
        outline: unset;
        opacity: 0.7;
      }
      .signin {
        color: #0056cd;
        font-weight: 600;
        cursor: pointer;
      }

      .getstarted {
        width: fit-content;
        background: #0056cd;
        border-radius: 5px;
        height: 43px;
        font-family: Poppins, BR Firma;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 19px;
        text-align: right;
        color: #ffffff;
        text-decoration: none;
        padding: 8px 16px;
        margin-right: unset;
        height: fit-content;
        &:active {
          opacity: 0.8;
        }
      }
    }
  }

  .mobile {
    display: none;
    padding: 0px 25px;
    background: #ffffff;

    .inner-top {
      .v-222 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 70px;
      }

      .layer2 {
        background: #ffffff;
        display: flex;
        flex-direction: column;

        .textsd {
          margin-bottom: 16px;
        }

        .linksd {
          font-family: Poppins, BR Firma;
          font-style: normal;
          font-weight: 400;
          font-size: 1em;
          line-height: 20px;
          color: #1e2329;
          text-decoration: none;
          margin-bottom: 20px;
        }
        .linksd:focus,
        .linksd:active,
        .signin:active,
        .signin:focus {
          outline: unset;
        }
        .signin {
          color: #0056cd;
          font-weight: 600;
        }

        .getstarted {
          background: #0056cd;
          border-radius: 5px;
          width: 100% !important;
          height: 43px !important;
          font-family: Poppins, BR Firma;
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 19px;
          color: #ffffff;
          text-decoration: none;
          padding: unset !important;
          margin: 0 0 20px 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

@media only screen and (max-width: 920px) {
  .desktop {
    display: none !important;
  }

  .mobile {
    display: block !important;
  }
}

.selected-nav-link {
  color: #0056cd !important;
}

.headercomp2 {
  .linksd {
    color: #565656 !important;
  }
}

.arrow-d {
  margin-left: 3px;
  transition: 0.5s;
  &.rotate213 {
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

.download-app-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 11px 16px;
  background: #001f4b;
  border-radius: 4px;
  color: white;
  font-weight: 600;

  @media only screen and (max-width: 920px) {
    margin-bottom: 20px;
  }
}
