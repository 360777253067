.cookie-wrapper {
  .cookie-policy-container {
    position: fixed;
    bottom: 7%;
    left: 50%;
    font-size: 14px;
    width: 86vw;
    max-width: 900px;
    transform: translateX(-50%);
    z-index: 10000000000000000;
    align-items: center;
    padding: 5px 10px 10px 10px;
    box-shadow: 0px 3px 10px -1px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    background-color: white;
    line-height: 25px;
    @media only screen and (max-width: 786px) {
      bottom: 15%;
    }
    .content-c {
      margin: auto 0;
    }
    .cookie-icon {
      margin-right: 7px;
      transform: translateY(6px);
    }
    .cookies-link {
      color: #0056cd;
      cursor: pointer;
      padding: 0 4px;
      font-weight: 500;
      text-decoration: none !important;
      &:active {
        opacity: 0.8;
      }
    }
    .accept-button {
      margin-left: 10px;
      padding: 2px 10px;
      background: #0056cd;
      border: 1px solid #0056cd;
      color: white;
      border-radius: 5px;
      cursor: pointer;
      &:active {
        opacity: 0.8;
      }
    }
  }
}
