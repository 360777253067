@import "../../index.scss";

.landingpage-2 {
  .invisible {
    visibility: hidden;
  }
  .visible {
    visibility: visible;
  }
  .landingpage-00 {
    .familybackground {
      background: #ebf3ff;
    }
    .familybackground > div {
      @include set-max-width-desktop;
      padding: 100px 20px;
      padding-bottom: 0;
      min-height: 621px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .showmesomethings {
        width: 50%;
        display: flex;
        flex-direction: column;
        // margin-top: 7%;
      }
      .sided1 {
        //   width: 55%;
        .phone-image {
          max-width: 100%;
          max-height: 621px;
          height: auto;
          width: auto;
          margin-bottom: -6px;
        }
      }
      .organiset {
        font-family: Poppins, BR Firma;
        font-style: normal;
        font-weight: 800;
        font-size: 3rem;
        max-width: 570px;
        text-align: left !important;
        color: #0056cd;
        span {
          color: #00b0f0;
        }
      }
      .organisec {
        font-family: Poppins, BR Firma;
        font-style: normal;
        font-weight: 300;
        font-size: 1.25em;
        text-align: left;
        color: #4f5d6d;
        margin-top: 14px !important;
        margin-bottom: 14px !important;
      }

      .tryc {
        font-family: Poppins, BR Firma;
        font-style: normal;
        font-weight: normal;
        font-size: 1em;
        line-height: 20px;
        color: #1e2329;
        margin-top: 15px !important;
        span {
          font-weight: bold;
          color: #1e2329;
        }
      }
    }
    .how-it-works {
      @include set-max-width-desktop;
      padding: 100px;
      .beg1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 15px;
        .journer {
          width: 800px;
          p {
            font-family: Poppins, BR Firma;
            font-style: normal;
            font-weight: 600;
            font-size: 1.75em;
            text-align: center;

            color: #1e2329;
          }
        }

        .hereishow {
          width: 900px;
          display: flex;
          justify-content: flex-end;
          .hereishow-text {
            font-family: Poppins, BR Firma;
            font-style: italic;
            font-weight: 600;
            font-size: 18px;
            line-height: 23px;
            /* identical to box height */

            /* Primary Brand Color */

            color: #0056cd;
            .icon001 {
              margin-right: 10px;
            }
          }
        }
      }

      .cf3 {
        img {
          height: auto;
          max-height: 375px;
        }
      }
      // #cf3 {
      //     position: relative;
      // }

      // #cf3 img {
      //     position: absolute;
      // }
      // .top {
      //     animation-name: fade;
      //     animation-timing-function: ease-in-out;
      //     animation-iteration-count: infinite;
      //     animation-duration: 1s;
      //     animation-direction: alternate;
      //     background-color: white;

      // }

      // @keyframes fade {
      //     0% {
      //         opacity: 1;
      //     }
      //     25% {
      //         opacity: 1;
      //     }
      //     75% {
      //         opacity: 0;
      //     }
      //     100% {
      //         opacity: 0;
      //     }
      // }

      .image-how-it {
        margin: 6rem 4rem;
        @media only screen and (max-width: 1024px) {
          margin: 6rem 0rem;
        }
        display: flex;
        justify-content: space-between;
        .image-how-it-img-div {
          @media only screen and (min-width: 1024px) {
            max-width: 50%;
          }
          img {
            max-width: 90%;
          }
          svg {
            width: 525px;
          }
        }
        .left-img6 {
          padding-right: 20px;
        }

        .image-how-it-info {
          @media only screen and (min-width: 1024px) {
            max-width: 50%;
          }
          @media only screen and (max-width: 786px) {
            width: 100%;
          }

          display: flex;
          flex-direction: column;
          justify-content: center;
          .image-how-it-info-1 {
            font-family: Poppins, BR Firma;
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            color: #1e2329;
            margin-bottom: 1rem !important;
          }
          .image-how-it-info-2 {
            font-family: Poppins, BR Firma;
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            color: #4f5d6d;
          }
        }
      }
    }

    .secure-assets {
      background: #001f4b;
      padding: 100px 0;
      display: flex;
      justify-content: center;
      margin-bottom: 450px;
      position: relative;
      height: fit-content;

      @media only screen and (max-width: 786px) {
        margin-bottom: 200px;
      }
      .pixalo {
        position: absolute;
        bottom: -360px;
        @media only screen and (min-width: 745px) and (max-width: 786px) {
          bottom: -220px;
        }
        display: flex;
        justify-content: center;
      }

      .secure-assets-width {
        width: 670px;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: fit-content;
      }
      .secure-assets-t1 {
        font-family: Poppins, BR Firma;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 35px;
        text-align: center;

        /* White */

        color: #ffffff;
        margin-bottom: 18px !important;
      }

      .secure-assets-t2 {
        font-family: Poppins, BR Firma;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        text-align: center;

        /* White */

        color: #91b5e9;
        margin-bottom: 36px !important;
      }
    }

    .pricing-assets {
      padding-top: 450px;
      padding-bottom: 125px;
      display: flex;
      justify-content: center;
      .pricing-assets-inner {
        width: 670px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .pricing-header-1 {
        font-family: Poppins, BR Firma;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 35px;
        text-align: center;
        color: #1e2329;
        margin-bottom: 1rem !important;
      }

      .pricing-header-2 {
        font-family: Poppins, BR Firma;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        text-align: center;
        color: #4f5d6d;
      }
      .pricing-div {
        margin-top: 40px;
        background: #001f4b;
        border-radius: 5px;
        width: 480px;
        height: 10rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
        .pricing-div-freq {
          font-family: Poppins, BR Firma;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: #ebf3ff;
        }

        .savings {
          padding: 0;
          font-family: Poppins, BR Firma;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: white;
          text-align: center;
        }

        .pricing-div-freq-price {
          display: flex;
          align-items: center;
          justify-content: center;
          .pricing-div-freq-price-1 {
            margin-right: 1rem;
            font-family: Poppins, BR Firma;
            font-style: normal;
            font-weight: 600;
            font-size: 42px;
            line-height: 53px;
            /* identical to box height */

            text-align: center;

            /* White */

            color: #ffffff;
          }

          .pricing-div-freq-price-2 {
            font-family: Poppins, BR Firma;
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 23px;
            /* identical to box height */

            /* White */

            color: #ffffff;
          }
        }
      }
      .p-try-1 {
        margin-top: 20px !important;
        font-family: Poppins, BR Firma;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #1e2329;
      }
    }
  }

  .getstarted {
    background: #0056cd;
    border-radius: 5px;

    font-family: Poppins, BR Firma;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;

    /* White */

    color: #ffffff;
    padding: 15px 35px;
    border: none;
    outline: none;

    width: 220px;
    height: 56px;
    margin-right: 30px !important ;
  }

  .learn-more {
    @extend .getstarted;
    width: auto;
    height: auto;
    padding: 15px;
    text-decoration: none;

    @media only screen and (max-width: 786px) {
      margin: 0 !important;
      max-width: 80%;
    }
  }

  @media only screen and (max-width: 786px) {
    .familybackground > div {
      padding: 30px 10px 0px 10px !important;
      display: flex;
      flex-direction: column !important;
      .showmesomethings {
        width: unset !important;
        display: flex;
        flex-direction: column;
        padding: 0px 15px;
        // align-items: center;
        // text-align: center;
        a {
          display: none !important;
        }
      }
      .sided1 {
        display: flex;
        justify-content: center;
        width: 100% !important;
        .phone-image {
          width: 100%;
          height: 100%;
          margin-bottom: unset !important;
        }
      }

      .organisec {
        font-family: Poppins, BR Firma;
        font-style: normal;
        font-weight: 300;
        font-size: 1em !important;
        color: #ffffff;
        margin-top: 14px !important;
        margin-bottom: 0px !important;
      }

      .tryc {
        display: none !important;
      }
    }
    .how-it-works {
      padding: 85px 70px 100px;
    }
    .journer {
      width: 800px;
      p {
        font-family: Poppins, BR Firma;
        font-style: normal;
        font-weight: 600;
        font-size: 1.15em !important;
        text-align: center;

        color: #1e2329;
      }
    }
    .image-how-it {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      .image-how-it-img-div {
        svg {
          width: 200px;
        }
        margin-bottom: 15px;
      }
      .left-img6 {
        padding-right: 20px;
      }

      .image-how-it-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .image-how-it-info-1 {
          font-family: Poppins, BR Firma;
          font-style: normal;
          font-weight: 600;
          font-size: 22px;
          color: #1e2329;
        }
        .image-how-it-info-2 {
          font-family: Poppins, BR Firma;
          font-style: normal;
          font-weight: 300;
          font-size: 18px;
          color: #4f5d6d;
        }
      }
    }

    .depictreverse {
      flex-direction: column-reverse !important;
    }

    .beg1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;
      .journer {
        width: 100% !important;
      }

      .hereishow {
        width: 100% !important;
        display: flex;
        justify-content: center;
      }
    }

    // .secure-assets{

    // padding-left: 30px !important;
    // padding-right: 30px !important;

    // }
    .pricing-assets {
      padding-top: 200px !important;
      padding-bottom: 125px;
      @media only screen and (max-width: 786px) {
        padding-left: 5%;
        padding-right: 5%;
      }
      display: flex;
      justify-content: center;
      .pricing-assets-inner {
        width: 670px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .pricing-header-1 {
        font-family: Poppins, BR Firma;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 35px;
        text-align: center;
        color: #1e2329;
      }

      .pricing-header-2 {
        font-family: Poppins, BR Firma;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        color: #4f5d6d;
      }
      .pricing-div {
        margin-top: 40px;
        background: #001f4b;
        border-radius: 5px;
        width: 480px;
        padding: 20px;
        .pricing-div-freq {
          font-family: Poppins, BR Firma;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: #ebf3ff;
        }

        .pricing-div-freq-price {
          display: flex;
          align-items: center;
          justify-content: center;
          // margin-top: 41px !important;
          // margin-bottom: 40px !important;
          .pricing-div-freq-price-1 {
            font-family: Poppins, BR Firma;
            font-style: normal;
            font-weight: 600;
            font-size: 36px !important;
            line-height: 39px !important;
            /* identical to box height */

            text-align: center;

            /* White */

            color: #ffffff;
          }

          .pricing-div-freq-price-2 {
            font-family: Poppins, BR Firma;
            font-style: normal;
            font-weight: 300;
            font-size: 14px !important;
            line-height: 19px !important;
            /* identical to box height */

            /* White */

            color: #ffffff;
          }
        }
      }
      .p-try-1 {
        margin-top: 20px !important;

        font-size: 16px;
        line-height: 20px;
      }
    }
    @media only screen and (min-width: 745px) and (max-width: 786px) {
      .pixalo {
        img {
          max-height: 300px;
        }
      }
    }

    .pixalo {
      img {
        width: 450px;
      }
    }
    .secure-assets-width {
      width: 90% !important;
      padding-left: 5%;
      padding-right: 5%;
    }

    .pricing-assets {
      padding-top: 200px;
      padding-bottom: 125px;
      display: flex;
      justify-content: center;
      .pricing-assets-inner {
        width: 100% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .pricing-header-1 {
        font-family: Poppins, BR Firma;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 35px;
        text-align: center;
        color: #1e2329;
      }

      .pricing-header-2 {
        font-family: Poppins, BR Firma;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        color: #4f5d6d;
      }
      .pricing-div {
        margin-top: 40px;
        background: #001f4b;
        border-radius: 5px;
        width: 480px;
        padding: 20px;
        .pricing-div-freq {
          font-family: Poppins, BR Firma;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: #ebf3ff;
        }

        .pricing-div-freq-price {
          display: flex;
          align-items: center;
          justify-content: center;
          // margin-top: 41px !important;
          // margin-bottom: 40px !important;
          .pricing-div-freq-price-1 {
            font-family: Poppins, BR Firma;
            font-style: normal;
            font-weight: 600;
            font-size: 42px;
            line-height: 53px;
            /* identical to box height */

            text-align: center;

            /* White */

            color: #ffffff;
          }

          .pricing-div-freq-price-2 {
            font-family: Poppins, BR Firma;
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 23px;
            /* identical to box height */

            /* White */

            color: #ffffff;
          }
        }
      }
      .p-try-1 {
        margin-top: 20px !important;
        font-family: Poppins, BR Firma;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #1e2329;
      }
    }
  }

  @media only screen and (max-width: 745px) {
    .how-it-works {
      padding: 85px 30px 100px !important;
    }
    .pixalo img {
      max-width: 96%;
      max-height: 200px;
    }

    .landingpage-00 .pricing-assets .pricing-div {
      width: 90%;
    }
    .image-how-it {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      .image-how-it-img-div {
        img {
          width: 90%;
        }
        svg {
          width: 90%;
        }
      }
      .left-img6 {
        padding-right: unset;
      }

      .image-how-it-info {
        .image-how-it-info-1 {
          font-size: 20px !important;
          margin-bottom: 1rem !important;
        }
        .image-how-it-info-2 {
          font-size: 16px !important;
        }
      }
    }

    .landingpage-00 .secure-assets .pixalo {
      bottom: -150px !important;
    }
    .landingpage-00 .pricing-assets {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 0px;
    }
  }
}
