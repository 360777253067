@import "./styling/text.scss";
@import "./styling/mainstyling";

body {
  margin: 0px !important;
  font-family: Poppins, BR Firma;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@mixin set-max-width-desktop {
  max-width: 1230px;
  margin: auto;
}

@mixin set-max-width-desktop-larger {
  max-width: 1280px;
  margin: auto;
}

a {
  color: unset;
  text-decoration: none;
}

.click {
  cursor: pointer;
  &:active {
    opacity: 0.8;
  }
}

.no-select {
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.no-overflow {
  overflow-y: hidden;
  max-height: 100vh;
}

.ReactModal__Overlay {
  z-index: 1000 !important;
}
