@import "../../index.scss";

.covafooter-container {
  background: #f6f7f9;
}

.covafooter-1 {
  @include set-max-width-desktop;
  display: flex;
  justify-content: space-between;
  padding: 51px 20px 34px 66px;
  .logodiv {
    width: 320px;
    display: flex;
  }

  .urls {
    width: 320px;
    display: flex;
    flex-direction: column;

    .urls-header {
      font-family: Poppins, BR Firma;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 10px !important;
      color: #1e2329;
    }

    .urls-links {
      font-family: Poppins, BR Firma;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 25px;

      /* Type:/Primary Type */

      color: #1e2329;
      text-decoration: none;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .url-socials {
      display: flex;
      margin-top: 7px;
      .url-socials-t {
        margin-right: 25px;
      }
    }
  }
}
.covafooter-2 {
  border-top: 1px solid rgba(51, 51, 51, 0.13);
}

.covafooter-2 > div {
  @include set-max-width-desktop;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 25px 50px 30px;
  @media only screen and (max-width: 786px) {
    padding: 5px 5px 5px !important;
    width: 100%;
  }
  .covafooter-2-1 {
    p {
      font-family: Poppins, BR Firma;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 18px;
      color: #1e2329;
    }
  }

  .covafooter-2-2 {
    a {
      text-decoration: unset;
    }
    .links-3 {
      font-family: Poppins, BR Firma;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 18px;
      color: #1e2329;
      margin-right: 15px !important;
    }
  }
}

.mobile44 {
  background: #dfe9f6;
  display: none;
  position: fixed;
  width: 100%;
  padding: 20px 0 16px 0;
  left: 0px;
  bottom: 0px;
  z-index: 311;
  align-items: center;
  justify-content: center;
  a {
    background: #0056cd;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #fff;
    text-decoration: none;
    border: none;
    outline: none;
    padding: 20px 30px;
  }
  @media only screen and (max-width: 786px) {
    display: flex;
  }
}

@media only screen and (max-width: 786px) {
  .covafooter-1 {
    display: flex;
    justify-content: space-between;
    padding: 51px 20px 34px 20px !important;
    flex-wrap: wrap;

    .urls {
      width: 320px;
      display: flex;
      flex-direction: column;
      margin-top: 30px;
    }
  }

  .covafooter-2 {
    border-top: 1px solid rgba(51, 51, 51, 0.13);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 25px 20px 30px !important;
    margin-bottom: 70px;
    .covafooter-2-1 {
      p {
        font-family: Poppins, BR Firma;
        font-style: normal;
        font-weight: 300;
        font-size: 12px !important;
        line-height: 18px;
        color: #1e2329;
      }
    }

    .covafooter-2-2 {
      a {
        text-decoration: unset;
      }
      .links-3 {
        font-family: Poppins, BR Firma;
        font-style: normal;
        font-weight: 300;
        font-size: 12px !important;
        line-height: 18px;
        color: #1e2329;
        margin-right: 15px !important;
      }
    }
  }
}
