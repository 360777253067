.review-card {
  display: block;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 10px 15px;
  width: fit-content;
  background-color: white;
  .user-info {
    display: flex;
    flex-direction: row;
    align-items: center;

    .u-i-left {
      margin-right: 12px;
      img {
        height: 39px;
        width: 39px;
      }
    }

    .u-i-right {
      div:nth-child(1) {
        color: #828282;
        font-size: 10px;
        display: flex;
        align-items: center;

        span {
          margin-left: 7px;
        }
      }

      div:nth-child(2) {
        font-size: 14px;
        font-weight: 600;
        text-align: left;
      }
    }
  }

  .r-c-text {
    margin-top: 5px;
    color: #6f6f6f;
    font-size: 11px;
    max-width: 330px;
    line-height: 19px;
    font-weight: 300;
    text-align: left;
  }
}
