@import "../../index.scss";

.frequently-asked {
  margin: 120px 0;
  padding: 0 24px;
  .frequently-asked-wrapper {
    @include set-max-width-desktop;
    .title-f {
      font-size: 24px;
      line-height: 36px;
      font-weight: 700;
      margin-bottom: 30px;
    }

    .frequently-asked-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @media only screen and (max-width: 786px) {
        display: block !important;

        img {
          width: calc(100vw - 48px);
          max-width: 500px;
        }
      }
      .faqs-container {
        display: block;
        width: 50%;
        @media only screen and (max-width: 786px) {
          width: 100%;
          margin-bottom: 70px;
        }
        .faq-item {
          padding: 10px;
          background: #f6f7f9;
          border: 1px solid #e1e7ec;
          box-sizing: border-box;
          border-radius: 4px;
          cursor: pointer;
          margin-bottom: 15px;
          &.selected {
            background: #ebf3ff;
          }
          &:active {
            opacity: 0.8;
          }
          .faq-title {
            font-size: 14px;
            color: #1e2329;
            font-weight: 500;
            margin-bottom: 10px;
          }
          .faq-desc {
            color: #4f5d6d;
            font-size: 12px;
            line-height: 20px;
          }
        }
      }
      .frequently-asked-image {
        position: relative;
        width: 46%;
        margin: auto 0;
        @media only screen and (max-width: 786px) {
          width: calc(100vw - 48px);
          max-width: 490px;
        }
        .blue-edge {
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(20px, -50px);
        }
        img {
          width: 100%;
        }
      }
    }
  }
}
