.payment-modal {
  .p-title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
  }

  .p-desc {
    font-size: 14px;
    color: #5f6369;
    margin-bottom: 10px;
  }

  .p-change-card {
    background: #ebf3ff;
    border: 1px solid #d6e7ff;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    padding: 10px 16px;
    margin-bottom: 12px;

    .c-c-left {
      display: block;

      .l-top {
        color: #1e2329;
        font-weight: 500;
      }

      .l-bottom {
        color: #4f5d6d;
        font-size: 12px;
      }
    }

    .c-c-right {
      color: #0056cd;
      font-weight: 600;
    }
  }

  .chosen {
    font-weight: 500;
    font-size: 14px;
    color: black;
    margin-bottom: 20px;

    span {
      color: #4f5d6d;
      font-weight: 400;
    }
  }
  .stripe-form {
    .form-label {
      font-weight: 500;
      font-size: 14px;
    }

    .form-item {
      margin-bottom: 20px;
      margin-top: 5px;
      border-radius: 4px;
      background-color: rgb(241, 243, 245);
      padding: 16px 12px;
    }

    .form-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .form-label {
        width: 49%;
      }
    }

    .stripe-form-btn {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 10px;

      .s-f-btn {
        background: #0056cd;
        border-radius: 4px;
        color: #ffffff;
        font-weight: 600;
        font-size: 14px;
        border: unset;
        padding: 10px 15px;
      }
    }
  }

  .powered {
    text-align: center;
    margin-top: 60px;
    font-size: 14px;
    color: #4f5d6d;

    svg {
      transform: translateY(3.5px);
    }
  }
}

.setup-payment {
  max-width: 670px;
  margin: 150px auto 0;
  padding: 0 20px;
  width: calc(100% - 40px);

  @media only screen and (max-width: 786px) {
    margin-top: 60px;
  }
}

.discount-code {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: 20px 0;
  position: relative;

  &.add-space {
    margin-bottom: 40px;
  }

  @media only screen and (max-width: 586px) {
    display: block;
  }

  .remove-discount-title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      margin-right: 5px;
    }
  }

  .d-c-input {
    width: 100%;
    // width: calc(100% - 186px);
    // @media only screen and (max-width: 586px) {
    //   width: 100%;
    // }
    input[type="text"]:disabled {
      color: gray !important;
    }
  }

  .discount-slash {
    color: #4f5d6d;
    font-size: 12px;
    margin-top: 3px;
    position: absolute;
    @media only screen and (max-width: 586px) {
      font-size: 11px;
    }
  }

  .d-c-button {
    font-weight: 600;
    font-size: 14px;
    color: white;
    background: #0056cd;
    border-radius: 4px;
    width: 170px;
    padding: 13.5px 0;
    text-align: center;
    margin-left: 16px;

    &.remove {
      color: #4f5d6d;
      background: #ebeff4;
      cursor: default;
      @media only screen and (max-width: 586px) {
        margin-top: 24px;
      }
    }

    @media only screen and (max-width: 586px) {
      margin-left: unset;
      margin-top: 10px;
      padding: 10px 0px;
      width: unset;
    }
  }
}
