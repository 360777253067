@import "../../index.scss";

.blog-posts {
  @include set-max-width-desktop;
  margin-top: 150px;
  padding: 0 24px;
  @media only screen and (max-width: 786px) {
    margin-top: 50px;
  }

  .b-p-wrapper {
    position: relative;

    .b-p-main {
      display: flex;
      flex-direction: row;
      overflow-x: scroll;
      scroll-behavior: smooth;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }

      padding-bottom: 12px;
      @media only screen and (max-width: 786px) {
        margin-bottom: 20px;
      }

      .b-p-item {
        width: calc(33% - 46px);
        min-width: 400px;
        margin-right: 23px;

        @media only screen and (max-width: 786px) {
          min-width: 335px;
          width: calc(100% - 30px);
          margin-right: 20px;
        }

        &:last-child {
          margin-right: 0px;
        }

        .b-top {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 52px 30px;
          position: relative;
          border-radius: 5.77655px 5.77655px 0px 0px;
          height: 80px;
          @media only screen and (max-width: 786px) {
            padding: 50px 20px;
          }

          &.one {
            background: #4d9bed;
          }
          &.two {
            background: #8488e5;
            img {
              height: 190px !important;
              right: -45px !important;
            }
          }
          &.three {
            background: #ff6058;
          }
          &.four {
            background: #0056cd;
            img {
              top: 0px !important;
              height: 190px !important;
            }
          }
          &.five {
            background: #f8cd47;
            img {
              right: -20px !important;
            }
          }
          &.six {
            background: #03b29d;
          }

          .b-t-left {
            display: block;
            width: 55%;
            @media only screen and (max-width: 786px) {
              width: 60%;
            }

            .l-title {
              color: #f6f7f9;
              font-weight: 600;
              font-size: 24px;
              margin-bottom: 4px;
              @media only screen and (max-width: 786px) {
                font-size: 22px;
              }
            }

            .l-desc {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #f6f7f9;
              @media only screen and (max-width: 786px) {
                font-size: 12px;
                line-height: 15px;
              }
            }
          }

          .b-t-right {
            img {
              position: absolute;
              height: 150px;
              width: auto;
              right: 0;
              top: 10px;
              @media only screen and (max-width: 786px) {
                transform: scale(0.8, 0.8) translateX(12.4%);
              }
            }
          }
        }

        .b-bottom {
          padding: 23px 21px;
          border: 0.722068px solid #e1e7ec;
          box-shadow: 0px 1.44414px 2.88827px rgba(0, 0, 0, 0.1);
          border-radius: 0px 0px 5.77px 5.77px;
          height: 140px;
          position: relative;

          .b-b-row-one {
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color: #1e2329;
            margin-bottom: 7px;
          }

          .b-b-row-two {
            color: #4f5d6d;
            font-size: 14px;
            line-height: 24px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            @media only screen and (max-width: 786px) {
              font-size: 12px;
              line-height: 20px;
            }

            @supports (-webkit-line-clamp: 2) {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: initial;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }

          .b-b-row-three {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            bottom: 17px;
            left: 21px;
            right: 21px;

            .r-t-left {
              display: flex;
              flex-direction: row;

              div {
                color: #4f5d6d;
                font-size: 12px;
                padding: 4px 14px;
                background: #f6f7f9;
                border-radius: 5.77655px;

                &:first-of-type {
                  margin-right: 8px;
                }
              }
            }

            .r-t-right {
              font-weight: 600;
              font-size: 12px;
              display: flex;
              align-items: center;

              span {
                margin-right: 5.7px;
              }
            }
          }
        }
      }
    }

    .b-p-ctrl {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 10;

      &.left {
        left: 0px;
      }

      &.right {
        right: 0px;
      }

      svg {
        height: 48px;
        width: 48px;
      }
    }

    .view-all {
      margin: 24px auto 200px;
      text-align: center;
      color: #4f5d6d;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      padding: 12px 100px;
      background: #ebeff4;
      border-radius: 5px;
      width: fit-content;

      @media only screen and (max-width: 786px) {
        margin-bottom: 100px;
      }
    }
  }
}
