@import "../../index.scss";

[id] {
  scroll-margin-top: 6rem;
}

.invisible {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.getstarted {
  background: #0056cd;
  border-radius: 5px;
  width: fit-content;
  text-decoration: none;
  font-family: Poppins, BR Firma;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  padding: 15px 27px;
  border: none;
  outline: none;
  margin-right: 30px;
}

.learn-more {
  @extend .getstarted;
  width: auto;
  height: auto;
  padding: 15px;
  text-decoration: none;

  @media only screen and (max-width: 786px) {
    margin: 0 !important;
    max-width: 80%;
  }
}

@media only screen and (max-width: 786px) {
  .depictreverse {
    flex-direction: column-reverse !important;
  }

  @media only screen and (min-width: 745px) and (max-width: 786px) {
    .pixalo {
      img {
        max-height: 300px;
      }
    }
  }

  .pixalo {
    img {
      width: 450px;
    }
  }
}

@media only screen and (max-width: 745px) {
  .how-it-works {
    padding: 5px 25px !important;
  }
  .pixalo img {
    max-width: 96%;
    max-height: 200px;
  }

  .image-how-it {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    .image-how-it-img-div {
      img {
        width: 90%;
      }
      svg {
        width: 90%;
      }
    }
    .left-img6 {
      padding-right: unset;
    }

    .image-how-it-info {
      .image-how-it-info-1 {
        font-size: 20px !important;
        margin-bottom: 1rem !important;
      }
      .image-how-it-info-2 {
        font-size: 16px !important;
      }
    }
  }
}

.landing-page-container {
  .l-p-banner {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.5) -4.88%,
      rgba(255, 255, 255, 0.5) 58.56%,
      rgba(226, 238, 255, 0.5) 95.12%
    );
    padding: 0px 25px;
    .banner-inner {
      @include set-max-width-desktop;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 80px 0;

      @media only screen and (max-width: 786px) {
        display: block;
        padding: 40px 0 70px;
      }

      .b-left {
        width: 48%;
        color: #494949;
        max-width: 530px;

        @media only screen and (max-width: 786px) {
          width: 100%;
          max-width: unset;
          margin-bottom: 30px;
          text-align: center;
        }

        .b-l-row-one {
          font-size: 12px;
          margin-bottom: 5px;
          @media only screen and (min-width: 786px) {
            &.small {
              display: none;
            }
          }
          @media only screen and (max-width: 786px) {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            span {
              margin-left: 5px;
            }
            &.large {
              display: none;
            }
          }
        }

        .b-l-row-two {
          font-weight: 600;
          font-size: 39px;
          line-height: 48px;
          margin-bottom: 20px;
          @media only screen and (max-width: 568px) {
            font-size: 29px;
            line-height: 37px;
          }
          span {
            color: #0056cd;
          }
        }

        .b-l-row-three {
          font-size: 15px;
          margin-bottom: 20px;
          line-height: 24px;
          @media only screen and (max-width: 786px) {
            font-size: 12px;
            line-height: 20px;
          }
        }

        .b-l-row-four-group {
          margin-bottom: 20px;
          @media only screen and (max-width: 786px) {
            display: none;
          }
        }

        .btn-review-group {
          @media only screen and (max-width: 786px) {
            display: none;
          }
        }

        .review-card {
          @media only screen and (max-width: 786px) {
            display: none;
          }
        }
      }

      .b-right {
        width: 55%;
        @media only screen and (max-width: 786px) {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
        }
        img {
          width: 100%;
          margin-top: -130px;
          @media only screen and (max-width: 786px) {
            max-width: 450px;
            margin-top: -50px;
          }
        }
      }

      .b-small-extra {
        margin-top: 20px;
        @media only screen and (min-width: 786px) {
          display: none;
        }

        .e-row-one {
          display: flex;
          flex-direction: row;
          justify-content: center;
          max-width: 400px;
          margin: 0 auto;
          margin-bottom: 20px;

          .r-f-item {
            font-size: 10px;
            color: #494949;
            margin-bottom: unset !important;

            .r-f-icon {
              transform: translateY(4px) !important;
            }
          }

          .divider23 {
            width: 1%;
            margin: 0 20px;
            border-left: 1px solid rgba(196, 196, 196, 0.2);
          }
        }

        .btn-review-group {
          @media only screen and (min-width: 786px) {
            display: none;
          }
        }
      }

      .r-f-item {
        margin-bottom: 10px;
        font-size: 13px;
        display: flex;
        flex-direction: row;

        .r-f-icon {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 21px;
          height: 21px;
          margin-right: 10px;

          .r-f-img {
            position: relative;
            z-index: 2;
            transform: translateY(1px);
          }
          .r-f-rect {
            position: absolute;
            left: 0;
            z-index: 1;
          }
        }
      }
    }
  }

  .media-feature-wrapper {
    background: #ffffff;
    border: 1px solid #e8e8e8;
    box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    width: 80%;
    max-width: 1050px;
    margin: 0 auto;
    transform: translateY(-60px);
    @media only screen and (max-width: 786px) {
      transform: translateY(-40px);
    }

    .media-coverage {
      padding: 10px !important;

      .mc-main {
        padding-bottom: unset !important;
      }
      .mc-title {
        margin-bottom: unset !important;
        font-size: 15px !important;
        text-align: center;
        color: #7d7d7d;
        @media only screen and (max-width: 786px) {
          font-size: 10px !important;
        }
      }

      @media only screen and (max-width: 786px) {
        img {
          width: 120px !important;
        }
      }
    }
  }

  .section-two {
    @include set-max-width-desktop;
    margin-top: 30px;
    margin-bottom: 80px;
    padding: 0 25px;

    .section-title292 {
      @media only screen and (max-width: 786px) {
        display: none;
      }
    }

    .s-t-tabs {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      border-bottom: 3px solid rgba(196, 196, 196, 0.5);
      width: fit-content;
      margin: 40px auto 0;
      @media only screen and (max-width: 786px) {
        display: none;
      }

      .tab-item {
        margin: 0 20px;
        padding-bottom: 10px;
        color: #808080;
        font-weight: 600;
        transition: 0.2s all ease-in-out;
        @media only screen and (max-width: 956px) {
          font-size: 14px;
          margin: 0 15px;
        }

        &.is-selected {
          color: #0056cd;
          padding-bottom: 13px;
          margin-top: -3px;
          border-bottom: 3px solid #0056cd;
          transform: translateY(3px);
        }

        &:nth-of-type(1) {
          margin-left: 0;
        }
        &:nth-last-of-type(1) {
          margin-right: 0;
        }
      }
    }

    .s-t-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 50px;
      @media only screen and (max-width: 786px) {
        margin-top: 10px;
      }

      .c-left {
        width: 50%;
        @media only screen and (max-width: 786px) {
          width: 100%;
        }
        .l-title {
          color: #494949;
          font-weight: 600;
          font-size: 20px;
          margin-bottom: 40px;
          margin-left: 52px;
          @media only screen and (max-width: 786px) {
            font-size: 24px;
            text-align: center;
            max-width: 310px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 20px;
            span {
              color: #0056cd;
            }
          }
        }

        .c-inner-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          .c-main {
            margin-bottom: 35px;
            @media only screen and (max-width: 786px) {
              width: 83%;
              background: #ffffff;
              border: 1px solid #dddddd;
              box-sizing: border-box;
              box-shadow: 0px 8px 10px 3px rgba(0, 0, 0, 0.05);
              border-radius: 11px;
              padding: 24px;
              margin-bottom: 20px;
            }

            .l-item {
              display: flex;
              flex-direction: row;
              &:nth-of-type(1) {
                margin-bottom: 35px;
              }

              @media only screen and (max-width: 786px) {
                display: block;
              }

              .l-i-icon {
                margin-right: 20px;
              }

              .l-i-content {
                .i-c-title {
                  font-weight: 600;
                  font-size: 15px;
                  color: #494949;
                  margin-bottom: 7px;

                  @media only screen and (max-width: 786px) {
                    font-size: 13px;
                  }
                }

                .i-c-text {
                  font-weight: 400;
                  font-size: 15px;
                  line-height: 28px;
                  letter-spacing: 0.04em;
                  color: #6f6f6f;
                  max-width: 514px;
                  @media only screen and (max-width: 786px) {
                    font-size: 12px;
                    line-height: 20px;
                  }
                }
              }
            }
          }
        }
      }

      .c-right {
        width: 50%;
        max-width: 470px;
        @media only screen and (max-width: 786px) {
          display: none;
        }

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .btn-review-group {
      @media only screen and (min-width: 786px) {
        margin-left: 52px;
      }
    }
  }

  .section-three {
    margin: 40px auto 80px;
    background: linear-gradient(0deg, #ffffff -4.85%, #ffffff 58.19%, #eff6ff 94.51%);
    padding-top: 60px;

    .s-t-inner {
      @include set-max-width-desktop;

      .s-t-main {
        margin-top: 80px;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .s-t-col {
          background: #ffffff;
          border: 1.5px solid #dddddd;
          box-sizing: border-box;
          border-radius: 11px;

          &.one {
            border-radius: 11px 0 0 11px;
            margin-right: -1.5px;
            @media only screen and (max-width: 786px) {
              width: 43% !important;
            }

            .col-item {
              padding-left: 12px;
              font-weight: 600;
              font-size: 14px;
              line-height: 30px;
              letter-spacing: 0.04em;
              color: #494949;
              display: flex;
              align-items: center;
              @media only screen and (max-width: 886px) {
                padding-left: 10px;
                font-size: 13px;
              }
              @media only screen and (max-width: 786px) {
                font-size: 11px;
                width: 100% !important;
              }

              .c-i-icon {
                margin-right: 10px;
                @media only screen and (max-width: 786px) {
                  display: none;
                }
              }
            }
          }

          &.two {
            margin-top: -55px;
            box-shadow: 0px 8px 10px 3px rgba(0, 0, 0, 0.05);
            margin-bottom: -25px;
            @media only screen and (max-width: 786px) {
              width: 24.5%;
            }
            .col-item {
              width: 323px;
              text-align: center;
              padding: 16.665px 0;
              &:nth-last-of-type(1) {
                border-bottom: unset;
              }
              @media only screen and (max-width: 786px) {
                width: 100%;
              }
            }

            .col-title {
              padding: 45px 0 !important;
              @media only screen and (max-width: 786px) {
                padding: 42.5px 0 !important;
              }
            }
          }
          &.three {
            border-radius: 0 11px 11px 0;
            margin-left: -1.5px;
            @media only screen and (max-width: 786px) {
              width: 24.5%;
            }
            .col-item {
              text-align: center;
              padding: 16.295px 0;

              &:nth-of-type(2) {
                padding: 16.667px 0;
              }

              @media only screen and (max-width: 786px) {
                width: 100% !important;
              }
            }
          }

          .col-title {
            border-bottom: 1px solid #dddddd;
            text-align: center;
            padding: 20px 0;
            font-weight: 600;
            font-size: 18px;
            @media only screen and (max-width: 786px) {
              font-size: 14px;
            }
          }

          .col-item {
            width: 251px;
            padding-top: 15px;
            padding-bottom: 15px;
            border-bottom: 1px solid #dddddd;
            &:nth-last-of-type(1) {
              border-bottom: unset;
            }
            &:nth-child(even) {
              background: rgba(249, 249, 249, 0.8);
            }
            @media only screen and (max-width: 886px) {
              width: 221px;
            }
          }
        }
      }

      .btn-review-group {
        margin: 60px auto 0;
        @media only screen and (max-width: 786px) {
          display: none;
        }
      }
    }
  }

  .section-four {
    background: linear-gradient(256.76deg, #0056cd 1.8%, #00357e 109%);
    transform: matrix(-1, 0, 0, 1, 0, 0);

    .s-f-inner {
      @include set-max-width-desktop;
      color: white;
      padding: 90px 25px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      transform: matrix(-1, 0, 0, 1, 0, 0);

      @media only screen and (max-width: 786px) {
        display: block;
      }

      .s-f-left {
        width: 40%;
        @media only screen and (max-width: 886px) {
          width: 45%;
        }
        @media only screen and (max-width: 786px) {
          width: 100%;
          text-align: center;
        }

        .f-row-one {
          font-weight: 600;
          font-size: 32px;
          line-height: 45px;
          letter-spacing: 0.02em;
          @media only screen and (max-width: 886px) {
            font-size: 27px;
          }
          @media only screen and (max-width: 786px) {
            font-size: 24px;
          }
        }

        .f-row-two {
          font-weight: 500;
          font-size: 16px;
          line-height: 28px;
          margin: 20px 0;
          opacity: 0.7;
          @media only screen and (max-width: 886px) {
            font-size: 14px;
          }
          @media only screen and (max-width: 786px) {
            font-size: 14px;
            font-weight: 400;
          }
        }

        .btn-review-group {
          @media only screen and (max-width: 786px) {
            display: none;
          }
        }
      }

      .s-f-right {
        width: 53%;
        @media only screen and (max-width: 886px) {
          width: 50%;
        }
        @media only screen and (max-width: 786px) {
          width: 100%;
        }
        img {
          width: 100%;
        }
      }

      .sm-only {
        margin-top: 20px;
        width: calc(100% - 27px) !important;
        margin: 0 auto;
        @media only screen and (min-width: 786px) {
          display: none;
        }
      }
    }

    .s-f-curve {
      background: #0056cd;
    }
  }

  .section-five {
    padding: 90px 25px;
    @include set-max-width-desktop;
    @media only screen and (max-width: 786px) {
      padding: 50px 25px;
    }
    .section-desc292 {
      @media only screen and (max-width: 786px) {
        display: none;
      }
    }

    .s-main-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .ctrl-btn {
        @media only screen and (min-width: 786px) {
          display: none;
        }
      }
    }

    .s-five-main {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 50px;
      @media only screen and (max-width: 786px) {
        margin-top: 30px;
      }

      .s-f-item {
        width: 22%;
        @media only screen and (max-width: 886px) {
          width: 24%;
        }

        @media only screen and (max-width: 786px) {
          display: none;
          width: 83%;
          background: #ffffff;
          border: 1px solid #dddddd;
          box-sizing: border-box;
          box-shadow: 0px 8px 10px 3px rgba(0, 0, 0, 0.05);
          border-radius: 11px;
          padding: 24px;
          text-align: center;
          margin: 0 auto 20px;
          max-width: 450px;

          &.visible {
            display: block;
          }
        }

        .i-image23 {
          width: 100%;
          margin-bottom: 30px;
          @media only screen and (max-width: 786px) {
            margin-bottom: 20px;
          }

          img {
            width: 100%;
            max-width: 230px;
            border-radius: 16px;
            margin: 0 auto;
            @media only screen and (max-width: 786px) {
              max-width: 135px;
            }
          }
        }

        .i-title23 {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.04em;
          color: #494949;
          margin-bottom: 10px;
          @media only screen and (max-width: 886px) {
            font-size: 14px;
          }
        }

        .i-desc23 {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.04em;
          color: #6f6f6f;
          @media only screen and (max-width: 886px) {
            font-size: 12px;
          }
        }
      }
    }
  }

  .section-six {
    margin-bottom: 20px;
    padding: 0 25px;
    @media only screen and (max-width: 786px) {
      background: linear-gradient(180deg, #ffffff -4.85%, #ffffff 58.19%, #eff6ff 94.51%);
      transform: rotate(180deg);
    }
    .s-s-wrapper {
      @include set-max-width-desktop;
      background: rgba(0, 0, 0, 0.02);
      border-radius: 15px;
      padding: 20px;
      @media only screen and (max-width: 786px) {
        padding: 20px 0 0;
        background: unset;
        transform: rotate(-180deg);
      }
    }

    .section-title292 {
      font-size: 26px;
    }

    .s-s-main-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;

      .s-s-main {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;

        .card-container {
          width: 29%;
          @media only screen and (max-width: 786px) {
            width: 83%;
            display: none;

            &.visible {
              display: block;
            }
          }

          .review-card {
            height: fit-content;
          }
        }
      }
    }
  }

  .section-eight {
    background: linear-gradient(252.83deg, #0056cd 7.34%, #00357e 108.49%);

    .s-e-wrapper {
      @include set-max-width-desktop;
      padding: 70px 25px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: white;
      text-align: center;
      @media only screen and (max-width: 786px) {
        flex-direction: column;
      }

      .s-e-item {
        width: 28%;
        @media only screen and (max-width: 786px) {
          width: 100%;
        }

        .item-row-two {
          margin: 18px 0;
          font-weight: 600;
          font-size: 18px;
          letter-spacing: 0.04em;
        }

        .item-row-three {
          font-weight: 400;
          font-size: 14px;
          line-height: 23px;
          opacity: 0.8;
          max-width: 300px;
          margin: 0 auto;
        }
      }

      .divider {
        width: 1%;
        border-left: 1.5px solid rgba(255, 255, 255, 0.1);
        height: 96px;
        margin: auto 0;
        @media only screen and (max-width: 786px) {
          height: 1%;
          width: 250px;
          margin: 40px auto;
          border-bottom: 1.5px solid rgba(255, 255, 255, 0.1);
        }
      }
    }
  }

  .section-nine {
    @include set-max-width-desktop;
    margin: 80px auto 50px;
    padding: 0 25px;
    @media only screen and (max-width: 786px) {
      margin: 50px auto 30px;
    }

    .s-n-image {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      img {
        width: 100%;
        &.s-n-i-lg {
          max-width: 800px;
          @media only screen and (max-width: 786px) {
            display: none;
          }
        }
        &.s-n-i-sm {
          max-width: 450px;
          @media only screen and (min-width: 786px) {
            display: none;
          }
        }
      }
    }

    .btn-review-group {
      margin: 40px auto;
    }

    .s-n-group {
      display: flex;
      flex-direction: row;
      justify-content: center;

      @media only screen and (min-width: 786px) {
        align-items: center;
      }
      @media only screen and (max-width: 786px) {
        flex-wrap: wrap;
        max-width: 400px;
        margin: 0 auto;
      }

      .s-n-item {
        margin: 0 10px;
        color: #6f6f6f;
        font-size: 14px;
        display: flex;
        flex-direction: row;

        @media only screen and (min-width: 786px) {
          background: #ffffff;
          border: 1px solid #e8e8e8;
          border-radius: 8px;
          align-items: center;
          padding: 20px;
        }

        @media only screen and (max-width: 786px) {
          width: 130px;
          font-size: 10px;
          line-height: 16px;

          &:nth-of-type(1),
          &:nth-of-type(2) {
            border-bottom: 1px solid #f4f4f4;
            padding-bottom: 10px;
          }
          &:nth-of-type(3),
          &:nth-of-type(4) {
            padding-top: 10px;
          }
        }

        &.sm-only {
          @media only screen and (min-width: 786px) {
            display: none;
          }
        }

        .s-n-icon {
          margin-right: 10px;
          width: 28px;
          height: 28px;
          @media only screen and (max-width: 786px) {
            height: 18px;
            width: 18px;
            &.rs {
              height: 24px;
              width: 24px;
            }
          }
        }
      }
    }

    .text2376 {
      color: #7d7d7d;
      font-size: 11px;
      text-align: center;
      margin-top: 13px;
      @media only screen and (max-width: 786px) {
        display: none;
      }
    }
  }
}

//general-styles

.blue-btn {
  padding: 12px 25px;
  border-radius: 5px;
  background: #0056cd;
  color: white;
  font-weight: 500;

  &.light {
    color: #0056cd;
    background: white;
  }
}

.section-title292 {
  font-weight: 600;
  font-size: 32px;
  color: #494949;
  text-align: center;
  @media only screen and (max-width: 786px) {
    font-size: 24px;
  }

  span {
    color: #0056cd;
  }
}

.section-desc292 {
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #6f6f6f;
  max-width: 680px;
  margin: 15px auto 0;

  @media only screen and (max-width: 786px) {
    font-size: 12px;
    line-height: 20px;
  }
}

.btn-review-group {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  @media only screen and (max-width: 786px) {
    width: 100% !important;
  }

  .blue-btn {
    box-shadow: 0px 8px 12px 3px rgba(192, 219, 255, 0.8);
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    width: fit-content;
    &.light {
      box-shadow: unset;
    }

    @media only screen and (max-width: 786px) {
      width: calc(100% - 50px);
      justify-content: center;
    }

    span {
      margin-left: 20px;
      transform: translateY(2px);
    }
  }

  .r-f-desc {
    font-size: 10px;
    color: #7d7d7d;
    &.light {
      color: white;
    }
  }
}

.svg-light {
  path {
    fill: #0056cd !important;
    stroke: #0056cd !important;
  }
}

.dots {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
  @media only screen and (min-width: 786px) {
    display: none;
  }

  .dot-item {
    width: 6px;
    height: 6px;
    background: #c4c4c4;
    border-radius: 50%;
    margin: 0 2px;

    &.is-active {
      background: #000000;
    }
  }
}

.ctrl-btn {
  @media only screen and (min-width: 786px) {
    display: none;
  }

  &.disabled {
    opacity: 0.3;
  }
}

.s-six-action {
  @include set-max-width-desktop;
  padding: 0 25px;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
