.coupon-details {
  padding: 24px;
  width: calc(100vw - 96px);
  max-width: 400px;
  margin: 0 auto;

  .c-d-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: -10px;
  }

  .c-d-main {
    text-align: center;

    .c-d-m-row-one {
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 4px;
    }

    .c-d-m-row-two {
      font-size: 14px;
      color: #4f5d6d;
      margin-bottom: 14px;
    }

    .c-d-m-row-three {
      font-size: 30px;
      color: #0056cd;
      font-weight: 600;
      margin-bottom: 14px;
    }
  }
}
