.media-coverage {
  margin: 0 auto;
  padding: 30px 25px 120px 25px;
  @media only screen and (max-width: 786px) {
    padding: 30px 25px 40px 25px;
  }
  max-width: 1100px;
  .mc-inner-wrapper {
    .mc-title {
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 23px;
    }

    .mc-main {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      overflow-x: scroll;
      padding-bottom: 20px;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      a {
        margin-right: 30px;
        transition: 0.5s all ease-in-out;
        &:nth-last-child(1) {
          margin-right: unset;
        }
        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
}
