@import "../../index.scss";

.business-page-container {
  overflow-x: hidden;
  .b-p-banner {
    padding: 130px 20px;
    @media only screen and (max-width: 600px) {
      padding: 80px 20px;
    }

    .b-row-one {
      font-size: 60px;
      font-weight: 600;
      text-align: center;
      max-width: 1000px;
      margin: 0 auto;

      @media only screen and (max-width: 800px) {
        font-size: 50px;
      }
      div {
        color: #0056cd;
        position: relative;
        width: fit-content;
        margin: 0 auto;

        .curve12 {
          position: absolute;
          bottom: 10px;
          left: 16px;
          z-index: -1;
          @media only screen and (max-width: 800px) {
            left: -1px;
            bottom: 5px;
          }
        }
      }
    }

    .b-row-two {
      display: flex;
      justify-content: center;
      margin-top: 50px;
    }

    .b-row-three {
      margin-top: 80px;
      display: flex;
      justify-content: center;
      @media only screen and (max-width: 600px) {
        margin-top: 60px;
      }

      img {
        width: 100%;
        max-width: 900px;
      }
    }
  }

  .b-features {
    @include set-max-width-desktop;
    margin: 0 auto;

    .b-f-inner {
      margin: 0 20px;
      padding: 60px 25px;
      background: #001f4b;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      color: #d6e7ff;

      @media only screen and (max-width: 768px) {
        flex-direction: column;
        margin: 0;
        border-radius: 0px;
      }

      .b-f-item {
        width: 30%;
        max-width: 290px;

        @media only screen and (max-width: 768px) {
          width: 100%;
          margin-bottom: 40px;
          max-width: 390px;
        }

        .f-icon {
          margin-bottom: 15px;
        }

        .f-title {
          margin-bottom: 7px;
          font-weight: 600;
        }

        .f-desc {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }

  .b-benefits {
    margin: 150px auto;
    position: relative;
    padding: auto 0;
    overflow-x: hidden;

    @media only screen and (max-width: 768px) {
      margin: 50px auto;
    }

    .b-b-inner {
      max-width: 1190px;
      margin: 0 auto;
      width: calc(100% - 40px);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      @media only screen and (max-width: 768px) {
        flex-direction: column;
      }

      .c-b-left {
        width: 40%;

        @media only screen and (max-width: 768px) {
          width: 100%;
        }

        .b-l-item {
          margin-bottom: 40px;
          transition: 0.3s;
          cursor: default;

          &:hover {
            padding: 14px 20px;
            box-shadow: 0px 4px 20px rgba(0, 69, 164, 0.3);
            border-radius: 5px;
          }

          .l-i-title {
            font-size: 20px;
            font-weight: 700;
            color: #0056cd;
            margin-bottom: 12px;
            position: relative;
            min-width: 291px;

            .curve12 {
              position: absolute;
              z-index: -1;
              left: 40px;
              top: 30%;
              width: 40%;
              &.dc {
                left: 25px;
              }
              &.dm {
                left: 25px;
                width: 35%;
              }
            }
          }

          .l-i-desc {
            font-size: 14px;
            line-height: 28px;
            color: #1e2329;
            max-width: 440px;

            @media only screen and (max-width: 1000px) {
              font-size: 12px;
            }

            @media only screen and (max-width: 768px) {
              font-size: 14px;
            }
          }
        }
      }

      .c-b-right {
        width: 54%;
        height: inherit;
        padding: 140px 0;
        position: relative;

        @media only screen and (max-width: 768px) {
          width: 100%;
        }

        .blue-bg {
          background-color: #0056cd;
          position: absolute;
          top: 0;
          right: -500px;
          left: 100px;
          bottom: 0;
          z-index: -1;

          @media only screen and (max-width: 768px) {
            left: 50px;
          }
        }

        .b-r-img-container {
          position: relative;
          width: fit-content;

          img {
            width: 100%;
          }

          .vect24 {
            position: absolute;
            height: 200px;
            width: auto;
            z-index: 3;
            @media only screen and (max-width: 768px) {
              height: 150px;
            }

            &.a {
              bottom: -90px;
              right: -100px;
              z-index: 4;
              height: 260px;
              @media only screen and (max-width: 768px) {
                height: 190px;
                right: -70px;
                bottom: -50px;
              }
            }

            &.b {
              bottom: 10px;
              right: -20px;
            }
          }
        }
      }
    }
  }

  .request-access {
    background: #001f4b;
    color: #ebf3ff;

    .r-a-inner {
      @include set-max-width-desktop;
      padding: 0 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      @media only screen and (max-width: 768px) {
        flex-direction: column;
        padding: 30px 20px;
      }

      .r-a-left {
        width: 62%;
        padding: 30px 0;

        @media only screen and (max-width: 768px) {
          width: 100%;
        }

        .a-l-row-one {
          font-weight: 600;
          font-size: 28px;
          line-height: 42px;
          max-width: 660px;
          margin-bottom: 20px;

          @media only screen and (max-width: 968px) {
            font-size: 24px;
            line-height: 35px;
          }
        }

        .a-l-row-two {
          line-height: 28px;
          max-width: 510px;
          margin-bottom: 30px;

          @media only screen and (max-width: 968px) {
            font-size: 14px;
          }
        }
      }

      .r-a-right {
        width: 35%;
        min-width: 390px;

        @media only screen and (max-width: 768px) {
          display: none;
        }
        img {
          width: 100%;
          margin-top: -110px;
          margin-bottom: -7px;
          transform: translateX(40px);
        }
      }
    }
  }

  .b-p-btn {
    font-weight: 600;
    font-size: 18px;
    color: white;
    padding: 14px 67px;
    background: #0056cd;
    border-radius: 4px;
    width: fit-content;

    &.light {
      color: #1e2329;
      background: white;
    }

    @media only screen and (max-width: 768px) {
      font-size: 16px;
      padding: 14px 50px;
    }
  }

  .covafooter-2 {
    margin-bottom: 0px !important;
  }
}
