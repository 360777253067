@import "../../index.scss";

.how-it-works {
  &.no-scroll {
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
  }
  .how-hero {
    padding: 80px 24px;
    @include set-max-width-desktop;
    margin: 0 auto;
    @media only screen and (max-width: 786px) {
      padding: 30px 0 60px 0;
      margin: 0 auto;
      .how-hero-wrapper {
        font-size: 24px !important;
        line-height: 35px !important;
        display: block !important;
        .how-hero-text {
          width: 100% !important;
          margin-bottom: 30px;
        }
      }
    }
    .how-hero-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .how-hero-text {
        width: 48%;
        .how-hero-title {
          font-size: 42px;
          line-height: 130%;
          margin-bottom: 30px;
          font-weight: 600;
          span {
            color: #0056cd;
          }
        }
        .how-hero-desc {
          font-weight: 300;
          font-size: 16px;
          line-height: 28px;
          color: #1e2329;
        }
      }
      .how-it-works-video {
        width: 48%;
        @media only screen and (max-width: 786px) {
          width: 100%;
          padding: 0px !important;
          .video-bg {
            margin: 0 auto;
            max-width: 500px !important;
            width: 100% !important;
            margin: unset !important;
            padding: 65px 0 !important;
            font-size: 15px !important;
            .play-button {
              height: 50px !important;
            }
            .video-desc {
              transform: translateY(40px) !important;
            }
            .man-jumping {
              height: 170px !important;
              top: 3% !important;
              left: 28% !important;
              transform: translate(25%, 3%) !important;
            }
          }
        }
        .how-it-works-wrapper {
          .video-bg {
            width: 100%;
            margin: 0 auto;
            padding: 100px 0;
            text-align: center;
            background: #2f548a;
            display: block;
            color: white;
            font-weight: 500;
            font-size: 20px;
            line-height: 36px;
            max-width: 1000px;
            border-radius: 13px;
            position: relative;
            .man-jumping {
              height: 250px;
              position: absolute;
              top: 3%;
              left: 30%;
              transform: translate(30%, 3%);
              z-index: 1;
              opacity: 0.7;
            }
            .play-button {
              cursor: pointer;
              position: relative;
              z-index: 2;
              transform: translateY(30px);
              &:active {
                opacity: 0.9;
              }
            }
            .video-desc {
              position: relative;
              z-index: 3;
              transform: translateY(60px);
            }
          }
        }
      }
    }
  }

  .how-divider {
    color: #0056cd;
    text-align: center;
    font-size: 28px;
    font-weight: 500;
    margin-top: 120px;
    @media only screen and (max-width: 786px) {
      margin-top: 80px;
      font-size: 20px;
      span {
        display: none;
      }
    }
    span {
      margin-left: 29px;
    }
  }

  .how-main {
    margin-bottom: 50px;
    @include set-max-width-desktop;
    padding: 0px 24px;
    @media only screen and (max-width: 786px) {
      padding: 0 !important;
    }

    .image-how-it {
      margin: 2rem 0;
      display: flex;
      justify-content: space-between;
      @media only screen and (max-width: 786px) {
        margin: 3rem 0 !important;
      }
      .image-how-it-img-div {
        @media only screen and (min-width: 1024px) {
          width: 45%;
        }
        img {
          max-width: 90%;
        }
        svg {
          width: 525px;
        }
      }
      @media only screen and (min-width: 786px) {
        .left-img6 {
          padding-right: 20px;
        }
      }
      @media only screen and (max-width: 786px) {
        .how-it-works-image {
          max-width: calc(100vw - 50px);
          height: unset !important;
          display: block;
        }
      }
      .how-text-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 53%;
        @media only screen and (max-width: 786px) {
          width: 100%;
          margin-top: 20px;
        }
        .number-how {
          font-size: 48px;
          color: #0045a4;
          position: relative;
          height: 68px;
          width: 68px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin-bottom: 30px;
          span {
            position: relative;
            z-index: 2;
            text-align: center;
          }
          .how-ellipse {
            position: absolute;
            left: 0;
            z-index: 1;
          }
        }
        .title-how {
          font-weight: 500;
          font-size: 22px;
          line-height: 36px;
          color: #0056cd;
          margin-bottom: 20px;
          max-width: 460px;
        }
        .desc-how {
          font-size: 14px;
          line-height: 28px;
          color: #4f5d6d;
          max-width: 470px;
        }
      }
    }
  }
}

.player-wrapper {
  position: relative;
  width: 100vw;
  height: auto;
  min-height: 500px;
  height: 100vh;
  max-height: 600px;
  max-width: 850px;
  @media only screen and (max-width: 786px) {
    min-height: unset !important;
    height: 300px !important;
    width: calc(100vw - 24px);
    max-width: 600px !important;
  }
}

.react-player {
  position: absolute;
  z-index: 200;
  top: 0;
  left: 0;
}
