@import "../../index.scss";

.testimonies-section {
  margin: 0 auto;
  background: #f6f7f9;
  padding-top: 61px;
  padding-bottom: 100px;

  @media only screen and (max-width: 1000px) {
    margin-bottom: 0;
  }

  .testimonies-wrapper {
    margin: 0 auto;

    .testimonies-main {
      @include set-max-width-desktop;
      padding: 55px 25px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      @media only screen and (max-width: 786px) {
        padding: 45px 15px;
      }

      .c-btn {
        svg {
          @media only screen and (max-width: 886px) {
            height: 54px;
            width: 54px;
          }
          @media only screen and (max-width: 786px) {
            height: 47px;
            width: 47px;
          }
          @media only screen and (max-width: 650px) {
            height: 37px;
            width: 37px;
          }
        }
        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }

      .testimonies {
        width: calc(100% - 270px);
        @media only screen and (max-width: 886px) {
          width: calc(100% - 200px);
        }
        @media only screen and (max-width: 786px) {
          width: calc(100% - 160px);
        }
        @media only screen and (max-width: 650px) {
          width: calc(100% - 100px);
          margin: 0 -10px;
        }

        .testimony {
          min-height: 190px;

          .text101 {
            font-weight: 500;
            font-size: 24px;
            line-height: 44px;
            color: #1e2329;
            margin-bottom: 18px;
            @media only screen and (max-width: 886px) {
              font-size: 22px;
              line-height: 42px;
            }
            @media only screen and (max-width: 786px) {
              font-size: 18px;
              line-height: 35px;
            }
            @media only screen and (max-width: 650px) {
              font-size: 14px;
              line-height: 30px;
            }

            span {
              font-size: 48px;
              @media only screen and (max-width: 886px) {
                font-size: 44px;
              }
              @media only screen and (max-width: 786px) {
                font-size: 40px;
              }
              @media only screen and (max-width: 650px) {
                font-size: 25px;
              }
            }
          }

          .user101 {
            font-weight: 600;
            font-size: 16px;
            color: #0045a4;
            @media only screen and (max-width: 786px) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  &.blue-bg231 {
    background: #0056cd;

    .t91-one,
    .t91-two,
    .text101 {
      color: white !important;
    }

    .user101 {
      color: #c2dcff !important;
    }

    .testimonies-wrapper {
      background: #0056cd;
    }
  }
}

.title911 {
  display: block;
  text-align: center;
  font-weight: 600;
  margin-bottom: 12px;

  .t91-one {
    font-size: 14px;
    color: #0056cd;
    text-transform: uppercase;
  }

  .t91-two {
    color: #1e2329;
    font-size: 32px;
  }
}
