@import "../../index.scss";

.why-cova {
  .why-hero {
    padding: 40px 24px;
    @include set-max-width-desktop;
    margin: 0 auto;
    @media only screen and (max-width: 786px) {
      padding: 30px 24px 60px 24px;
      margin: 0 auto;
      .why-hero-wrapper {
        font-size: 24px !important;
        line-height: 35px !important;
        display: block !important;
        .why-hero-text,
        .why-hero-image {
          width: calc(100vw - 48px) !important;
          max-width: 600px !important;
          img {
            margin-top: 50px;
            width: calc(100vw - 48px) !important;
            max-width: 500px !important;
          }
        }
      }
    }
    .why-hero-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .why-hero-text {
        max-width: 800px;
        width: 50%;
        .why-hero-title {
          font-size: 34px;
          line-height: 120%;
          margin-bottom: 36px;
          color: #1e2329;
          font-weight: 700;
          font-size: 46px;
          span {
            color: #0056cd;
          }
        }
        .why-hero-desc {
          font-weight: 300;
          font-size: 16px;
          line-height: 28px;
          color: #4f5d6d;
          margin-bottom: 36px;
        }
        .why-hero-link {
          font-style: italic;
          font-weight: normal;
          font-size: 14px;
          color: #1e2329;
          &:active {
            opacity: 0.8;
          }
        }
      }
      .why-hero-image {
        width: 47%;

        img {
          width: 100%;
        }
      }
    }
  }

  .why-metrics {
    @include set-max-width-desktop;
    padding: 30px 24px;
    margin-bottom: 70px;

    .why-metrics-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .why-card-one {
        width: 49%;
        background: #0056cd;
        font-weight: 500;
        font-size: 24px;
        color: white;
        position: relative;
        .card-one-wrapper {
          padding: 100px 41px;
          .pink {
            position: absolute;
            bottom: 30px;
            right: 30px;
            z-index: 1;
          }
          .blue {
            position: absolute;
            top: 10px;
            left: 80px;
            z-index: 1;
          }
          span {
            position: relative;
            z-index: 2;
          }
        }
      }

      .why-card-two {
        width: 49%;
        background: #c2dcff;
        .card-two-wrapper {
          padding: 20px 41px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          height: calc(100% - 40px);
          .why-cards-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .card-two-item {
              width: 45%;
              color: #0045a4;
              .item-amount {
                font-weight: 600;
                font-size: 36px;
                margin-bottom: 10px;
              }
              .item-desc {
                font-weight: 500;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
  .why-main {
    @include set-max-width-desktop;
    padding: 30px 24px;

    .why-main-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 130px;
      @media only screen and (max-width: 786px) {
        display: block;
        margin-bottom: unset !important;
        .why-main-item {
          width: 100% !important;
          margin-bottom: 50px !important;
        }
      }
      .why-main-item {
        width: 45%;
        @media only screen and (max-width: 786px) {
          width: 100%;
          margin-top: 20px;
        }

        .title-why {
          font-weight: 500;
          font-size: 20px;
          line-height: 36px;
          color: #0056cd;
          margin-bottom: 10px;
        }
        .desc-why {
          font-size: 16px;
          line-height: 28px;
          color: #4f5d6d;
        }
      }
    }
  }

  .why-it-works-video {
    @include set-max-width-desktop;
    padding: 0px 24px;
    margin-bottom: 30px;
    @media only screen and (max-width: 786px) {
      width: calc(100vw - 50px);
      padding: 0px !important;
      .video-bg {
        width: 100% !important;
        padding: 70px 0 !important;
        font-size: 18px !important;
        .play-button {
          height: 70px !important;
        }
      }
    }
    .why-it-works-wrapper {
      .video-bg {
        width: 90%;
        margin: 0 auto;
        padding: 180px 0;
        text-align: center;
        background: #2f548a;
        display: block;
        color: white;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        max-width: 1000px;
        border-radius: 20px;
        .play-button {
          cursor: pointer;
          &:active {
            opacity: 0.8;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 786px) {
  .why-metrics {
    margin-bottom: 30px !important;

    .why-metrics-wrapper {
      display: block !important;
      .why-card-one,
      .why-card-two {
        width: 100% !important;
        margin-bottom: 20px;
      }
      .card-one-wrapper {
        padding: 50px 31px !important;
        font-size: 16px !important;
        overflow: hidden !important;
        .pink,
        .blue {
          height: 70%;
        }
        .pink {
          bottom: 10px !important;
          right: 10px !important;
        }
        .blue {
          top: 10px !important;
          left: 10px !important;
        }
      }
      .card-two-wrapper {
        padding: 15px !important;
        .card-two-item {
          width: 49% !important;
          &:nth-of-type(1) {
            margin-bottom: 15px;
          }
          .item-amount {
            font-size: 20px !important;
            margin-bottom: 5px !important;
          }
          .item-desc {
            font-size: 13px !important;
          }
        }
      }
    }
  }
}

.flag {
  height: 20px;
  transform: translate(-4px, 5px);
}
